import { MAX_FILES } from '@utils/variables'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Heading, ImgContainer } from 'ui'

const DragAndDropPreview: FC = () => {
  const { t } = useTranslation('chat')

  return (
    <Container>
      <ImgContainer>
        <img
          src="/img/drag-and-drop-preview.svg"
          width={284}
          height={120}
          alt={t('dragAndDropAlt')}
        />
      </ImgContainer>
      <Heading variant="h2" as="p" pt="2rem">
        {t('dragAndDrop.title', { count: MAX_FILES })}
      </Heading>
    </Container>
  )
}

export default DragAndDropPreview

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background};
  opacity: 0.9;
  z-index: 10;
`
