import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { getFullName } from '@utils/helpers'
import { LOCALES, Language } from '@utils/locales'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { BaseUser } from 'src/shared/types/base-user'
import styled from 'styled-components'
import {
  Avatar,
  CloseIcon,
  Container,
  DownloadIcon,
  ModalButton,
  ModalButtonVariant,
  Text,
} from 'ui'

interface GalleryHeaderProps {
  attachment: AttachmentResponse
  onClose: VoidFunction
  onDownload: VoidFunction
  sender: BaseUser
  createdAt?: Date
}

export const GalleryHeader: FC<GalleryHeaderProps> = ({
  attachment,
  sender,
  onClose,
  onDownload,
  createdAt,
}) => {
  const { avatar, color, firstName, lastName, userId, isDeleted } = sender
  const { t } = useTranslation(['attachments', 'user-detail'])
  const { interfaceLanguage } = useInterfaceLanguage()

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId,
    // TODO: fix types
    //@ts-ignore
    avatarId: avatar?.id,
    width: AVATAR_PREVIEW_WIDTH.MD,
  })

  const formattedDate = format(new Date(createdAt || ''), 'MMMM d, yyyy h:mm a', {
    locale: LOCALES[interfaceLanguage.value as Language] || enUS,
  })

  return (
    <GalleryHeaderContainer>
      <MediaDetails>
        <Avatar
          label={firstName[0] ?? t('deactivatedAccount', { ns: 'user-detail' })}
          bgColor={`#${color}`}
          imgUrl={usersAvatarUrl}
          borderRadius="0.6rem"
          size="4.4rem"
          isAvatarExists={enabled}
          isDeleted={isDeleted}
        />
        <Container ml="1.2rem">
          <Text>
            {isDeleted
              ? t('deactivatedAccount', { ns: 'user-detail' })
              : getFullName(firstName, lastName)}
          </Text>
          <Text variant="smallTextRegular">
            {formattedDate} {attachment.name && ` • ${attachment.name}`}
          </Text>
        </Container>
      </MediaDetails>
      <GalleryHeaderActions>
        <ModalButton
          variant={ModalButtonVariant.LIGHT}
          onClick={onDownload}
          aria-label={t('download')}
        >
          <DownloadIcon />
        </ModalButton>
        <ModalButton variant={ModalButtonVariant.LIGHT} onClick={onClose} aria-label={t('close')}>
          <CloseIcon />
        </ModalButton>
      </GalleryHeaderActions>
    </GalleryHeaderContainer>
  )
}

const GalleryHeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 2rem 3rem;
  color: ${({ theme }) => theme.colors.white};
  background-image: linear-gradient(
    180deg,
    rgba(31, 28, 29, 0.8) 0%,
    rgba(31, 28, 29, 0.3) 50%,
    rgba(31, 28, 29, 0) 100%
  );
`

const MediaDetails = styled.div`
  display: flex;
  align-items: center;
`
const GalleryHeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
`
