import { useCreateChannel } from "@api/chats/hooks/use-create-channel"
import { useWorkspace } from "@hooks/use-workspace"
import { routes } from "@routes/flow/routes"
import { ConversationType } from "@shared/types/conversation"
import React, { FC, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useModal } from "ui"
import { CreateChannelForm } from "@modules/forms/create-channel"
import { FormikHelpers } from "formik"
import { initialValues } from "@modules/forms/create-channel/data"
import { useAppDispatch } from "@app/flow/hooks"
import { addChannel } from "src/entities/channels/model/slice"

export const CreateChannel: FC = () => {
  const { mutateAsync } = useCreateChannel()
  const { closeModal } = useModal()
  const { t } = useTranslation('modal-create-channel')
  const navigate = useNavigate()
  const { workspaceId } = useWorkspace()
  const dispatch = useAppDispatch()

  const channelTypeOptions = [
    { value: ConversationType.PUBLIC_CHANNEL, label: t('publicLabel'), desc: t('publicDesc') },
    { value: ConversationType.PRIVATE_CHANNEL, label: t('privateLabel'), desc: t('privateDesc') },
  ]

  const handleSubmit = useCallback(async (
    values: typeof initialValues,
    { setSubmitting, resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      const channelData = await mutateAsync({
        chatName: values.name,
        description: values.desc,
        conversationType: values.channelType,
      })
      resetForm()
      dispatch(addChannel(channelData))
      navigate(`${routes.workspaces}/${workspaceId}/${routes.channels}/${channelData.id}`)
      closeModal()
    } catch (error) {
      toast.error(`${t('createError')}`)
    } finally {
      setSubmitting(false)
    }
  }, [mutateAsync, workspaceId, navigate, closeModal, t])

  return (
    <CreateChannelForm
      onCancel={closeModal}
      onSubmit={handleSubmit}
      channelTypeOptions={channelTypeOptions}
    />
  )
}
