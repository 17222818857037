import { createSlice } from '@reduxjs/toolkit'
import { channelsEntityAdapter, channelsInitialState, selectChannelById } from './entity-adapter'

const channelsSlice = createSlice({
  name: 'channels',
  initialState: channelsInitialState,
  reducers: {
    setChannel: channelsEntityAdapter.setOne,
    setChannels: channelsEntityAdapter.setMany,
    joinChannel: (state, action) => {
      const id = action.payload
      const channel = selectChannelById(state, id)
      if (!channel) return

      channelsEntityAdapter.updateOne(state, {
        id,
        changes: { joined: true, membersCount: channel.membersCount + 1 },
      })
    },
    leaveChannel: (state, action) => {
      const id = action.payload
      const channel = selectChannelById(state, id)
      if (!channel) return

      const newMembersCount = channel.membersCount - 1
      if (newMembersCount <= 0) {
        channelsEntityAdapter.removeOne(state, id)
      } else {
        channelsEntityAdapter.updateOne(state, {
          id,
          changes: { joined: false, membersCount: newMembersCount },
        })
      }
    },
    addChannel: (state, action) => {
      const initialState = {
        membersCount: 1,
        joined: true,
      }
      channelsEntityAdapter.addOne(state, {
        ...action.payload,
        initialState,
      })
    },
  },
})

export const { setChannel, setChannels, joinChannel, leaveChannel, addChannel } = channelsSlice.actions
export default channelsSlice.reducer
