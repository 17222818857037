export enum ConversationType {
  'CHAT' = 'Chat',
  'PRIVATE_CHANNEL' = 'PrivateChannel',
  'PUBLIC_CHANNEL' = 'PublicChannel',
  'GENERAL_CHANNEL' = 'GeneralChannel',
}

export enum ChatType {
  CHAT = 0,
  CHANNEL = 1,
  PUBLIC_CHANNEL = 2,
  GENERAL_CHANNEL = 3,
}
