import { useIsTruncated } from '@hooks/use-is-truncated'
import React, { FC } from 'react'
import styled from 'styled-components'
import { LayoutProps, SpaceProps } from 'styled-system'
import { Container, Heading, Tooltip } from 'ui'

export interface SidepageHeaderProps {
  title: string
  preFix?: React.ReactNode
  postFix?: React.ReactNode
  lineClamp?: number
}

export type GeneralSidepageHeaderProps = LayoutProps & SpaceProps & SidepageHeaderProps

export const SidepageHeader: FC<GeneralSidepageHeaderProps> = ({
  title,
  preFix,
  postFix,
  lineClamp,
  ...spacing
}) => {
  const { isTruncated, textRef } = useIsTruncated()
  return (
    <SidepageHeaderContainer lineClamp={lineClamp} {...spacing}>
      {preFix && (
        <IconContainer as="span" mr="0.4rem">
          {preFix}
        </IconContainer>
      )}
      <Tooltip text={title} disabled={!isTruncated} placement="bottom">
        <Heading as="h2" variant="h2" lineClamp={lineClamp} ref={textRef}>
          {title}
        </Heading>
      </Tooltip>
      {postFix && (
        <IconContainer as="span" ml="0.4rem">
          {postFix}
        </IconContainer>
      )}
    </SidepageHeaderContainer>
  )
}

const IconContainer = styled(Container)`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SidepageHeaderContainer = styled(Container)<{ lineClamp?: number }>`
  display: flex;
  align-items: center;
  max-width: 26rem;
`
