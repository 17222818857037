import { User } from '@api/account/types/user'
import React, { FC } from 'react'
import { Container, Text } from 'ui'
import { ConversationType } from '.'
import { UserPreviewAvatar } from './user-preview-avatar'

export interface UserInfoText {
  title?: string
  subtitle?: string
}

interface UserInfoProps {
  type: ConversationType
  firstUser: User
  otherUsers: User[]
  userInfoText?: UserInfoText
}

export const UserInfo: FC<UserInfoProps> = ({ type, firstUser, otherUsers, userInfoText }) => {
  const { title, subtitle } = userInfoText || {}
  return (
    <Container display="flex" flexDirection="row">
      <Container display="flex" flexGap="1.2rem">
        {type === ConversationType.MY_SPACE ? (
          <UserPreviewAvatar user={firstUser} />
        ) : (
          otherUsers.map((user) => <UserPreviewAvatar key={user.userId} user={user} />)
        )}
      </Container>
      {userInfoText && (
        <Container display="flex" flexDirection="column" ml="1.2rem" justifyContent="center">
          {title && <Text variant="textMedium">{title}</Text>}
          {subtitle && <Text>{subtitle}</Text>}
        </Container>
      )}
    </Container>
  )
}
