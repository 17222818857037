import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { usePatchMe } from '@api/account/hooks/use-patch-me'
import { PatchMePath } from '@api/account/patch-me'
import { EditAvatar } from '@modules/edit-avatar'
import { AvatarType } from '@modules/modals/edit-avatar/types'
import { PersonalSettingsSkeleton } from '@modules/skeleton/skeleton-variants/personal-settings'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'
import React, { FC, useMemo } from 'react'
import { withSkeleton } from 'src/HOC/with-skeleton'
import { PersonalSettingsForm } from 'src/features/user/forms/settings'
import { ContentChats } from 'src/features/user/forms/settings/content-chats'
import { ContentFlow } from 'src/features/user/forms/settings/content-flow'
import { ChatsPersonalValues, FlowPersonalValues } from 'src/features/user/forms/settings/types'
import { Container } from 'ui'

export const PersonalSettings: FC = () => {
  const { meData } = useGetMe()
  const { mutate, isLoading } = usePatchMe()
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: meData?.userId,
    avatarId: meData?.avatar?.mediaId,
    isEnabled: !isNativeChatsApp(),
    width: AVATAR_PREVIEW_WIDTH.XL,
  })

  const chatsInitialValues: ChatsPersonalValues = useMemo(
    () => ({
      [PatchMePath.FirstName]: meData?.firstName || '',
      [PatchMePath.LastName]: meData?.lastName || '',
      [PatchMePath.PhoneNumber]: meData?.phoneNumber || '',
      [PatchMePath.Email]: meData?.email || '',
    }),
    [meData]
  )

  const flowInitialValues: FlowPersonalValues = useMemo(
    () => ({
      [PatchMePath.FirstName]: meData?.firstName || '',
      [PatchMePath.LastName]: meData?.lastName || '',
      [PatchMePath.PhoneNumber]: meData?.phoneNumber || '',
      [PatchMePath.Email]: meData?.email || '',
      [PatchMePath.JobTitle]: meData?.jobTitle || '',
      [PatchMePath.Timezone]: meData?.timezone || null,
    }),
    [meData]
  )

  const handleOnChatsSubmit = (values: ChatsPersonalValues) => mutate(values)

  const handleOnFlowSubmit = (values: FlowPersonalValues) => {
    const timezoneId = values.timezone?.id
    mutate({ ...values, [PatchMePath.Timezone]: timezoneId })
  }

  return (
    <Container maxWidth={{ _: '40rem', desktop: '60rem' }}>
      <EditAvatar
        mb="2.4rem"
        imgURL={usersAvatarUrl}
        avatarBgColor={meData?.color}
        avatarType={AvatarType.User}
        name={`${meData?.firstName ?? ''} ${meData?.lastName ?? ''}`}
        label={`${meData?.firstName?.charAt(0) ?? ''}`}
        isAvatarExists={enabled}
      />
      <PersonalSettingsForm
        initialValues={isNativeChatsApp() ? chatsInitialValues : flowInitialValues}
        onSubmit={isNativeChatsApp() ? handleOnChatsSubmit : handleOnFlowSubmit}
        isLoading={isLoading}
      >
        {isNativeChatsApp() ? <ContentChats /> : <ContentFlow />}
      </PersonalSettingsForm>
    </Container>
  )
}

export const PersonalSettingsWithSkeleton = withSkeleton(
  PersonalSettings,
  <PersonalSettingsSkeleton />
)
