import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useAppSelector } from '@app/flow/hooks'
import { EntityState } from '@reduxjs/toolkit'
import { getChatAvatar } from '@utils/get-chat-avatar'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'
import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Conversation } from 'src/entities/conversations/model/conversation'
import { ConversationType } from 'src/entities/conversations/model/conversation-type'
import {
  Indicators,
  ListItemText,
  NewMessageIndicator,
} from 'src/features/chats/components/conversation-list/conversation-item'
import { useTheme } from 'styled-components'
import { EditIcon, Tooltip } from 'ui'
import { selectUsersByIds } from '../../entities/teammates/api/model/slice'
import { User } from '../../entities/teammates/api/model/user-type'

export interface SidebarConversation extends Conversation {
  chatUserNames?: string
}

interface ChatListItemProps {
  item: SidebarConversation
  showStatus?: boolean
}

export interface ListItemTextProps {
  hasUnreadMessage?: boolean
  textRef?: RefObject<HTMLParagraphElement>
}

export interface ListProps {
  open: boolean
}

export const ChatListItem: FC<ChatListItemProps> = ({ item: { unreadMessagesCount }, item }) => {
  const { name, chatUsers, conversationType, chatUserNames } = item
  const [chatUserId, setChatUserId] = useState<string>('')
  const [avatarId, setAvatarId] = useState<string>()
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)
  const { meData } = useGetMe()
  const { t } = useTranslation('sidebar')

  const users = useAppSelector((state: { users: EntityState<User> }) =>
    selectUsersByIds(state.users, item?.chatUserIds)
  )

  const theme = useTheme()
  const isChat = conversationType === ConversationType.CHAT

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: chatUserId,
    avatarId,
    width: AVATAR_PREVIEW_WIDTH.XS,
  })

  const hasUnreadMessage = useMemo(() => unreadMessagesCount > 0, [unreadMessagesCount])

  /* TODO: Currently the EditIcon is not displayed when a chat has a draft message. Add this functionality so the EditIcon will be displayed when there will be some draft messages ont he specific chat.*/
  const hasDraftMessage = false

  useEffect(() => {
    if (isChat) {
      if (item.chatUsers.length === 1) {
        setChatUserId(chatUsers[0].user.userId)
        //@ts-ignore
        setAvatarId(chatUsers[0].user.avatar?.id)
      }

      if (item.chatUsers.length === 2) {
        const uniqUser = chatUsers.find((chatUser) => chatUser.user.userId !== meData?.userId)
        setChatUserId(uniqUser?.user.userId || '')
        //@ts-ignore
        setAvatarId(uniqUser?.user.avatar?.id)
      }
    }
  }, [item, setChatUserId, meData?.userId]) //be doesn't have avatars for chats yet

  useEffect(() => {
    textRef?.current && setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
  }, [textRef])

  const shouldShowTooltip = isChat ? item.chatUsers.length >= 4 || isTruncated : isTruncated
  const tooltipText = isChat && meData ? chatUserNames : name

  return (
    <>
      {getChatAvatar({
        ...item,
        avatar: item.avatar,
        label: item.name?.slice(0, 1),
        size: 24,
        borderRadius: '0.4rem',
        fontSize: '1.3rem',
        id: item.id,
        workspaceUsers: users,
        me: meData,
        imgURL: usersAvatarUrl,
        isAvatarExists: enabled,
      })}
      <Tooltip text={tooltipText} placement="right" disabled={!shouldShowTooltip}>
        <ListItemText ref={textRef} ml="0.8rem">
          {name}
        </ListItemText>
      </Tooltip>
      {(hasDraftMessage || hasUnreadMessage) && (
        <Indicators ml="auto">
          {hasDraftMessage && (
            <EditIcon
              fill={theme.colors.greyDark}
              width={16}
              height={16}
              aria-label={t('draftMessage')}
            />
          )}
          {hasUnreadMessage && <NewMessageIndicator aria-label={t('unreadMessage')} />}
        </Indicators>
      )}
    </>
  )
}
