import { ChatUser } from '@api/chats/types/chat-user'
import { Online } from '@api/chats/types/online'
import { ChatListItem, SidebarConversation } from '@components/chat-list-item'
import { Disclosure } from '@headlessui/react'
import { useLeftSideBar } from '@hooks/use-left-side-bar'
import { SIDEBAR_POPOVER_ID } from '@modules/sidebar'
import React, { FC, ReactElement, useState } from 'react'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'
import {
  ChevronUpIcon,
  Container,
  HiddenLabel,
  Popover,
  PopperAction,
  PopperList,
  StatusVariant,
  usePopover,
} from 'ui'
import {
  ArrowBtn,
  ListContainer,
  ListHeaderContainer,
  ListHeaderIcon,
  ListHeaderText,
  ListItemButton,
  ListItemsContainer,
  PrimaryActionIconBtn,
  SecondaryActionIconBtn,
  IconContainer
} from './styled'

export enum CollapsibleListItemTypes {
  PUBLIC_CHANNEL = 'public_channel',
  PRIVATE_CHANNEL = 'private_channel',
  DIRECT = 'direct',
  PROJECT = 'project',
  GROUP = 'group',
}

export interface ChatMember {
  value: string
  label: string
  id: string
  avatarName?: string
  avatarUrl?: string
  status?: StatusVariant
  count?: number
  color?: string
  jobTitle?: string
  online?: Online
  read: boolean
  chatUsers?: ChatUser[]
}

export interface CollapsibleListStyleProps extends SpaceProps {}

export interface PrimaryAction {
  name: string
  icon?: ReactElement
  popperOptions: PopperAction[]
}

export interface CollapsibleListProps {
  title: string
  itemList: SidebarConversation[]
  icon: ReactElement
  isOpenByDefault: boolean
  onItemClick?: (id: string) => void
  primaryAction?: PrimaryAction
  secondaryAction?: PopperAction
}

export interface ListItemButtonProps {
  isSelected: boolean
}

export interface ListItemTextProps {
  read: boolean
}

export interface ListProps {
  open: boolean
}

export interface ActionButtonProps {
  isOpen: boolean
}

export type GeneralCollapsibleListProps = CollapsibleListStyleProps & CollapsibleListProps

export const CollapsibleList: FC<GeneralCollapsibleListProps> = ({
  itemList,
  title,
  icon,
  onItemClick,
  isOpenByDefault,
  primaryAction,
  secondaryAction,
  ...spacing
}) => {
  const { selectedOption } = useLeftSideBar()
  const [hover, setHover] = useState(false)
  const theme = useTheme()
  const conversationsPopoverId = `${SIDEBAR_POPOVER_ID}-${primaryAction?.name}`
  const { isOpen } = usePopover(conversationsPopoverId)

  return (
    <ListContainer {...spacing}>
      <Disclosure defaultOpen={isOpenByDefault}>
        {({ open }) => (
          <>
            <Container
              data-cy="sidePageBtn"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mr="1.6rem"
              minHeight="2.8rem"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => !isOpen && setHover(false)}
            >
              <Disclosure.Button>
                <ListHeaderContainer>
                  <ListHeaderIcon>
                    <ArrowBtn open={open}>
                      <ChevronUpIcon fill={theme.colors.greyDark} />
                    </ArrowBtn>
                    <IconContainer>{icon}</IconContainer>
                  </ListHeaderIcon>
                  <ListHeaderText variant="captionMedium">{title}</ListHeaderText>
                </ListHeaderContainer>
              </Disclosure.Button>
              {hover && (
                <Container display="flex">
                  {primaryAction && (
                    <>
                      <HiddenLabel>{primaryAction.name}</HiddenLabel>
                      <Popover
                        placement="bottom-start"
                        popoverId={conversationsPopoverId}
                        popperTrigger={
                          <PrimaryActionIconBtn isOpen={isOpen}>
                            {primaryAction.icon}
                          </PrimaryActionIconBtn>
                        }
                        onClose={() => setHover(false)}
                      >
                        <PopperList actions={primaryAction.popperOptions} width="21.2rem" />
                      </Popover>
                    </>
                  )}
                  {secondaryAction && (
                    <SecondaryActionIconBtn onClick={secondaryAction.onClick}>
                      {secondaryAction.icon}
                    </SecondaryActionIconBtn>
                  )}
                </Container>
              )}
            </Container>
            {itemList.length > 0 && (
              <ListItemsContainer open={open}>
                {itemList.map((item) => (
                  <Disclosure.Panel key={item.id}>
                    <ListItemButton
                      isSelected={selectedOption === item.id}
                      onClick={() => {
                        onItemClick?.(item.id)
                      }}
                      id={item.id}
                    >
                      <ChatListItem item={item} />
                    </ListItemButton>
                  </Disclosure.Panel>
                ))}
              </ListItemsContainer>
            )}
          </>
        )}
      </Disclosure>
    </ListContainer>
  )
}
