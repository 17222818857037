import { ConversationList } from '@api/chats/types/chat-list'
import { DeleteMessageInput } from '@api/messages/delete-message'
import { EditMessageParams } from '@api/messages/edit-message'
import { GetMessageByIdProps } from '@api/messages/get-message-by-id'
import { SendMessageProps } from '@api/messages/send-message'
import { Message } from '@api/messages/types/message'
import { MessageDetail } from '@api/messages/types/message-detail'
import { connectionConfig } from '@contexts/signalr-provider'
import { ChatsService, MessagesService } from '@native-chats-api/chats/generated'

import {
  ChatApiService,
  AddUsersToChannelProps,
  CreateChatsProps,
  GetChatDetailsProps,
  LeaveChannelProps,
  UpdateChatProps,
  GetChatListProps,
  GetChannelListProps,
  ReportTranslationProps,
  UpdateTranslationPreferenceProps,
  UpdateTranslationLanguageProps,
  DeleteChatProps,
} from './chat-repository'
import { ChatType } from './entities'
import { convertChatResponseToChat } from './helpers/convertChatResponseToChat'
import { convertChatsListResponseToConversationList } from './helpers/convertChatsListResponseToConversationList'
import { ConversationType } from '@shared/types/conversation'

const getChatTypeForChats = (conversationType?: ConversationType) => {
  switch (conversationType) {
    case ConversationType.CHAT:
      return ChatType._0
    case ConversationType.PUBLIC_CHANNEL:
      return ChatType._1
    default:
      return ChatType['_-1']
  }
}

export class NativeChatsChatService implements ChatApiService {
  async addUsersToChat(props: AddUsersToChannelProps) {
    const chat = await ChatsService.postApiV2ChatsAddUsers(props.chatId, { userIds: props.userIds })
    return convertChatResponseToChat(chat)
  }

  async createChat({ chatName, userIds, conversationType }: CreateChatsProps) {
    const chat = await ChatsService.postApiV2Chats({
      userIds,
      name: chatName,
      chatType: getChatTypeForChats(conversationType),
    })
    return convertChatResponseToChat(chat)
  }

  // async deleteChannel(props: DeleteChannelProps) {
  //   ChatsService.deleteV3Chats(props.channelId)
  // }

  async getChatDetails(props: GetChatDetailsProps) {
    const chatDetails = await ChatsService.getApiV2Chats(props.chatId as string)
    return convertChatResponseToChat(chatDetails)
  }

  //   async getAttachment(conversationId: string, attachmentId: string) {
  //     const response = await FilesService.getApiV2Files([attachmentId])

  //     const files = response?.files || []

  //     const file = files[0]

  //     if (!file) {
  //       return {}
  //     }

  //     return convertFileToAttachmentResponse(file)
  //   }

  async leaveChannel(props: LeaveChannelProps) {
    const chatDetails = await ChatsService.getApiV2Chats(props.channelId)
    await ChatsService.deleteApiV2ChatsLeave(props.channelId)
    return convertChatResponseToChat(chatDetails)
  }

  async deleteChat(props: DeleteChatProps) {
    const chatDetails = await ChatsService.getApiV2Chats(props.chatId)
    await ChatsService.deleteApiV2ChatsLeave(props.chatId)
    return convertChatResponseToChat(chatDetails)
  }

  async updateChat(props: UpdateChatProps) {
    const chat = await ChatsService.patchApiV2ChatsUpdateInfo(props.chatId, props.requestBody)
    return convertChatResponseToChat(chat)
  }

  async getChatList(params: GetChatListProps) {
    const conversationList = await ChatsService.getApiV2ChatsList(
      params.pageParam ?? 1,
      params.pageSize ?? 20
    )
    const chatList: ConversationList = {
      chats: conversationList.chats?.map(convertChatsListResponseToConversationList) ?? [],
      pages: conversationList.pages!,
      totalChatsCount: conversationList.totalChatsCount!,
      currentPage: conversationList.currentPage!,
    }
    return chatList
  }

  async getChannelList(params: GetChannelListProps) {
    const conversationList = await ChatsService.getApiV2ChatsList(
      params.pageNumber ?? 1,
      params.pageSize ?? 20
    )
    const channelList: ConversationList = {
      chats: conversationList.chats?.map(convertChatsListResponseToConversationList) ?? [],
      pages: conversationList.pages!,
      totalChatsCount: conversationList.totalChatsCount!,
      currentPage: conversationList.currentPage!,
    }
    return channelList
  }

  sendMessage(props: SendMessageProps) {
    return MessagesService.postApiV2Messages(props) as Promise<Message>
  }

  deleteMessage({ messageId }: DeleteMessageInput) {
    return MessagesService.deleteApiV2Messages(messageId)
  }

  editMessage({ messageId, newText }: EditMessageParams) {
    return MessagesService.patchApiV2Messages(messageId, {
      newText,
    }) as Promise<Message>
  }

  async getMessage({ id }: GetMessageByIdProps) {
    const message = await MessagesService.getApiV2Messages(id)
    return { ...message, createdAt: new Date(message.createdAt!) } as MessageDetail
  }

  async reportTranslation(data: ReportTranslationProps) {
    const message = await MessagesService.postApiV2MessagesTranslationReport(data.messageId, {
      languageCode: data.languageCode,
    })
    return message as Message
  }

  async updateTranslationPreference(data: UpdateTranslationPreferenceProps) {
    const message = await MessagesService.postApiV2MessagesTranslationPreference(data.messageId, {
      languageCode: data.languageCode,
      provider: data.provider,
    })

    return message as Message
  }

  async updateTranslationLanguage(data: UpdateTranslationLanguageProps) {
    const message = await MessagesService.postApiV2MessagesTranslate(data.messageId, {
      secondaryLanguageCode: data.languageCode,
    })

    return { ...message, createdAt: new Date(message.createdAt!) } as MessageDetail
  }

  readConversation(conversationId: string) {
    return connectionConfig.invoke('ChatMessagesRead', conversationId)
  }
}
