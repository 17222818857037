import { Avatar } from '@api/account/types/avatar'
import { User } from '@api/account/types/user'
import { Avatar as ChatAvatar } from '@api/chats/types/avatar'
import { Chat } from '@api/chats/types/chat'
import { ChatUser } from '@api/chats/types/chat-user'
import { LastMessage } from '@api/chats/types/last-message'
import { Online } from '@api/chats/types/online'
import { ProviderTranslation } from '@api/chats/types/provider-translation'
import { Translation } from '@api/chats/types/translation'
import { Reaction } from '@api/messages/types/message-detail'
import { UserReactionTime as UserReactionTimeFlow } from '@api/messages/types/message-detail'
import { ConversationType as ConversationTypeFlow } from '@shared/types/conversation'
import {
  ChatType as ConversationTypeChats,
  MediaModel,
  ChatUserResponse,
  UserResponse,
  OnlineResponse,
  ChatUserMediaResponse,
  MessageReaction,
  UserReactionTime,
  MessageTranslationDto,
  MessageTranslationItemDto, //   AttachmentResponse,
  MessageDetailDto,
  ChatResponse,
} from '../entities'
import { mapMedia } from './convertChatsListResponseToConversationList'

export type UserTimeZone = {
  id?: string | null
  displayName?: string | null
}

export const convertChatResponseToChat = (chatResponse: ChatResponse): Chat => {
  return {
    id: chatResponse.id || '',
    name: chatResponse.name ?? '',
    description: '',
    color: chatResponse.color || '#fff',
    conversationType: convertConversationType(chatResponse.chatType || ConversationTypeChats._0),
    isMuted: chatResponse.isMuted ?? false,
    isBlocked: false,
    avatar: convertChatAvatar(chatResponse.avatar || {}),
    createdAt: new Date(chatResponse.createdAt as string) || '',
    chatUsers: chatResponse?.chatUsers?.map(convertChatUser) || [],
    lastMessage: convertLastMessage(chatResponse.lastMessage || {}),
    messagesDeletedToMessageId: chatResponse.messagesDeletedToMessageId || '',
    unreadMessagesCount: chatResponse.unreadMessagesCount || 0,
    createdBy: '',
    isDeleted: false,
  }
}

const convertAvatar = (avatarResponse: ChatUserMediaResponse): Avatar => {
  return {
    mediaId: avatarResponse.mediaId || '',
    mediaUri: avatarResponse.mediaUri || '',
    mediaUriExpiresAt: new Date().toISOString() || '',
  }
}

const convertChatAvatar = (avatarResponse: MediaModel): ChatAvatar => {
  return {
    mediaId: avatarResponse.mediaId || '',
    mediaUri: avatarResponse.mediaUri || '',
    mediaUriExpiresAt: new Date(avatarResponse.mediaUriExpiresAt as string) || '',
  }
}

const convertChatUser = (chatUserResponse: ChatUserResponse): ChatUser => {
  return {
    user: convertUser(chatUserResponse.user),
    lastMessageReadAt: new Date(chatUserResponse.lastMessageReadAt ?? ''),
    idOfLastMessageRead: chatUserResponse.idOfLastMessageRead ?? '',
    isOwner: chatUserResponse.isOwner ?? false,
    leftChat: chatUserResponse.leftChat ?? false,
  }
}

const convertUser = (userResponse?: UserResponse): User => {
  return {
    userId: userResponse?.userId ?? '',
    firstName: userResponse?.firstName ?? '',
    lastName: userResponse?.lastName ?? '',
    email: userResponse?.email ?? '',
    avatar: convertAvatar(userResponse?.avatar ?? {}),
    online: userResponse?.online
      ? convertOnlineResponse(userResponse.online)
      : { status: 0, lastOnline: new Date('') },
    phoneNumber: userResponse?.phoneNumber ?? '',
    jobTitle: '',
    primaryLanguageCode: 'en',
    timeZone: { displayName: '', id: '' }, // need to check how we can get timezone
    languages: [{ name: 'English', code: 'en', isPrimary: true, translatedName: '' }],
    isDeleted: userResponse?.isDeleted ?? false,
    color: userResponse?.color ?? '',
    doNotDisturbFrom: false,
    joiningDate: new Date(''),
  }
}

const convertOnlineResponse = (onlineResponse: OnlineResponse): Online => {
  return {
    status: onlineResponse.status ?? 0,
    lastOnline: new Date(onlineResponse.lastOnline as string) ?? '',
  }
}

// const convertLanguage = (language: Language): UserLanguage => {
//   return {
//     code: language.code ?? '',
//     name: language.name ?? '',
//     translatedName: language.translatedName ?? '',
//   }
// }

const convertLastMessage = (lastMessage: MessageDetailDto): LastMessage => {
  return {
    messageId: lastMessage.messageId ?? '',
    chatId: lastMessage.chatId ?? '',
    isDeleted: lastMessage.isDeleted ?? false,
    createdBy: lastMessage.createdBy ?? '',
    createdAt: new Date(lastMessage.createdAt ?? ''),
    updatedAt: new Date(lastMessage.editedAt ?? ''),
    reactions: lastMessage.reactions ? lastMessage.reactions.map(convertReaction) : [],
    originalMessage: lastMessage.originalMessage ?? '',
    sourceLanguageCode: lastMessage.sourceLanguageCode ?? '',
    clientMessageId: lastMessage.clientMessageId ?? '',
    translations: lastMessage.translations ? lastMessage.translations.map(convertTranslation) : [],
    media: lastMessage.media ? lastMessage.media.map(mapMedia) : [],
  }
}

const convertReaction = (reaction: MessageReaction): Reaction => {
  return {
    reactionCode: reaction.reactionCode ?? '',
    count: reaction.count ?? 0,
    createdAt: new Date(reaction.createdAt ?? ''),
    userReactionTimes: reaction.userReactionTimes
      ? reaction.userReactionTimes.map(convertUserReactionTime)
      : [],
  }
}

const convertUserReactionTime = (userReactionTime: UserReactionTime): UserReactionTimeFlow => {
  return {
    userId: userReactionTime.userId ?? '',
    timestamp: new Date(userReactionTime.timestamp ?? ''),
  }
}

const convertTranslation = (translation: MessageTranslationDto): Translation => {
  return {
    translationId: translation.translationId ?? '',
    languageCode: translation.languageCode ?? '',
    isPrimaryLanguage: translation.isPrimaryLanguage ?? false,
    translations: translation.translations
      ? translation.translations.map(convertProviderTranslation)
      : [],
    hasBeenReportedAsIncorrect: translation.hasBeenReportedAsIncorrect ?? false,
  }
}

const convertProviderTranslation = (
  providerTranslation: MessageTranslationItemDto
): ProviderTranslation => {
  return {
    provider: providerTranslation.provider ?? 0,
    translation: providerTranslation.translation ?? '',
    isPreferred: providerTranslation.isPreferred ?? false,
  }
}

const convertConversationType = (conversationType: ConversationTypeChats): ConversationTypeFlow => {
  switch (conversationType) {
    case ConversationTypeChats._0:
      return ConversationTypeFlow.CHAT
    case ConversationTypeChats._1:
      return ConversationTypeFlow.PUBLIC_CHANNEL
    default:
      return ConversationTypeFlow.CHAT
  }
}
