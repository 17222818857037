const nameLimits = { min: 1, max: 50 }
const phoneNumberLimits = { min: 7, max: 15 }
const jobTitleLimits = { min: 2, max: 100 }
const workspaceNameLimits = { min: 2, max: 100 }
const channelNameLimits = { min: 1, max: 50 }
const channelDescLimits = { min: 1, max: 250 }

const nameRegex = /^[\p{L}\p{M}\s'-.]+$/u
const phoneRegExp = /^[\+]?[(]?[0-9]{1,3}[)]?[-\s\.]?([0-9]{1,4}[-\s\.]?){1,3}[0-9]{1,4}$/
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
const upperLowerCaseRegexp = /(?=.*[a-z])(?=.*[A-Z])/
const numberRegexp = /\d/

export {
  nameLimits,
  phoneNumberLimits,
  jobTitleLimits,
  workspaceNameLimits,
  channelNameLimits,
  channelDescLimits,
  nameRegex,
  phoneRegExp,
  passwordRegExp,
  upperLowerCaseRegexp,
  numberRegexp,
}
