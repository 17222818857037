import { useGetMe } from '@api/account/hooks/use-get-me'
import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { useGetAllChatList } from '@api/chats/hooks/use-get-all-chat-list'
import { Chat } from '@api/chats/types/chat'
import { PrimaryAction } from '@components/collapsible-list'
import { TeammatesIcon, UnreadIcon } from '@components/icons'
import { StatusLabel } from '@components/status-label'
import { usePagination } from '@hooks/use-pagination'
import { useSkeleton } from '@hooks/use-skeleton'
import { CreateChannelModal } from '@modules/modals/create-channel'
import { SignOutModal } from '@modules/modals/sign-out-modal'
import { SidebarHeaderWithSkeleton } from '@modules/sidebar/header'
import { SidebarPopperAction } from '@modules/sidebar/popper'
import { SidebarChatsContentSkeleton } from '@modules/skeleton/skeleton-variants/sidebar-chats-content'
import { routes } from '@routes/chats/routes'
import { filterActiveConversations } from '@utils/filter-active-conversations'
import { filterDeletedUsers } from '@utils/filter-deleted-users'
import { getFullName } from '@utils/helpers'
import { notificationsManager } from '@utils/notifications'
import {
  AVATAR_PREVIEW_WIDTH,
  KNOWLEDGE_BASE_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_LINK,
} from '@utils/variables'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SkeletonComponentNames } from 'src/HOC/with-skeleton'
import { ConversationList } from 'src/features/chats/components/conversation-list'
import { ConversationListMenu } from 'src/features/chats/components/conversation-list-menu'
import {
  Avatar,
  ClipboardIcon,
  DirectMessageIcon,
  HelpIcon,
  LogoutIcon,
  PlusIcon,
  PopperAction,
  ScrollContainer,
  ShieldIcon,
  StatusVariant,
  useModal,
  UserIcon,
  VerticalMenuIcon,
} from 'ui'

export const Sidebar: FC = () => {
  const { meData, isLoading: isMeDataLoading } = useGetMe()
  const { showSkeleton, hideSkeleton } = useSkeleton()
  const { openModal } = useModal()
  const { t } = useTranslation('sidebar')
  const navigate = useNavigate()
  const {
    fetchedData,
    ref,
    isFetchingNextPage,
    isRefetching,
    isLoading: isChatsLoading,
  } = usePagination({
    hookConfig: {
      hook: useGetAllChatList,
    },
  })

  const isNextPageLoading = isFetchingNextPage || isRefetching

  const chats = fetchedData as Chat[]

  const isLoading = isChatsLoading || isMeDataLoading //isChannelsLoading ||

  const myActiveChats = useMemo(() => {
    if (!meData || !chats) return []
    const activeChats = filterActiveConversations(chats, meData.userId)
    return filterDeletedUsers(activeChats, meData.userId)
  }, [chats, meData])

  const unreadMessagesConversations = useMemo(() => {
    notificationsManager.setMappingDataForNotifications(chats).setNavigate(navigate)
    return chats.filter(({ unreadMessagesCount }) => unreadMessagesCount > 0)
  }, [chats, navigate])

  useEffect(() => {
    isMeDataLoading
      ? showSkeleton(SkeletonComponentNames.SIDEBAR_HEADER_1)
      : hideSkeleton(SkeletonComponentNames.SIDEBAR_HEADER_1)
  }, [isMeDataLoading])

  const userName = getFullName(meData?.firstName, meData?.lastName)

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: meData?.userId,
    //@ts-ignore
    avatarId: meData?.avatar?.id,
    width: AVATAR_PREVIEW_WIDTH.MD,
  })

  const onSignOutClick = () => {
    openModal({ content: <SignOutModal /> })
  }

  const handleRedirect = (link: string) => location.replace(link)

  const options: SidebarPopperAction[] = useMemo(
    () => [
      {
        name: t('teammates'),
        icon: <TeammatesIcon />,
        onClick: () => {
          navigate(routes.users)
        },
        group: 'group1',
      },
      {
        name: t('personalSettings'),
        icon: <UserIcon />,
        onClick: () => {
          navigate(routes.settings)
        },
        group: 'group1',
      },
      {
        name: t('knowledgeBase'),
        icon: <HelpIcon />,
        onClick: () => handleRedirect(KNOWLEDGE_BASE_LINK),
        group: 'group2',
      },
      {
        name: t('termsOfService'),
        icon: <ClipboardIcon />,
        onClick: () => handleRedirect(TERMS_LINK),
        group: 'group2',
      },
      {
        name: t('privacyPolicy'),
        icon: <ShieldIcon />,
        onClick: () => handleRedirect(PRIVACY_POLICY_LINK),
        group: 'group2',
      },
      {
        name: t('logoutOfNative'),
        icon: <LogoutIcon />,
        onClick: onSignOutClick,
        group: 'group3',
      },
    ],
    [t]
  )

  const chatPrimaryAction: PrimaryAction = useMemo(
    () => ({
      name: t('chatsMenu'),
      icon: <VerticalMenuIcon />,
      popperOptions: [
        {
          name: t('seeTeammates'),
          icon: <TeammatesIcon />,
          onClick: () => navigate(routes.users),
        },
        {
          name: t('createChannel'),
          icon: <PlusIcon />,
          onClick: () => openModal({ content: <CreateChannelModal />, maxWidth: '62rem' }),
        },
      ],
    }),
    [t]
  )

  const chatSecondaryAction: PopperAction = useMemo(
    () => ({
      name: t('newMessage'),
      icon: <PlusIcon />,
      onClick: () => navigate(`${routes.chats}/${routes.createNew}`),
    }),
    [t]
  )

  return (
    <>
      <SidebarHeaderWithSkeleton
        me={meData}
        onCreateNew={() => navigate(`${routes.chats}/${routes.createNew}`)}
        title={userName}
        subtitle={<StatusLabel isPopup={false} label="Online" status={StatusVariant.ONLINE} />}
        avatarComponent={
          <Avatar
            size="4.4rem"
            imgUrl={meData?.avatar?.mediaUri || usersAvatarUrl}
            bgColor={`#${meData?.color}`}
            label={userName}
            isAvatarExists={enabled}
          />
        }
        popperItems={options}
        componentName={SkeletonComponentNames.SIDEBAR_HEADER_1}
        px="2rem"
      />
      <ScrollContainer hideScrollbar>
        {isLoading ? (
          <SidebarChatsContentSkeleton />
        ) : (
          <>
            <ConversationList
              title={t('unread')}
              icon={<UnreadIcon isUnread={unreadMessagesConversations.length > 0} />}
              conversations={unreadMessagesConversations}
              myUserId={meData?.userId || ''}
              isOpen
            />
            <ConversationList
              title={t('chats')}
              icon={<DirectMessageIcon />}
              myUserId={meData?.userId || ''}
              conversations={myActiveChats}
              ref={ref}
              isNextPageLoading={isNextPageLoading}
              isAllChats
              isOpen
            >
              <ConversationListMenu
                primaryAction={chatPrimaryAction}
                secondaryAction={chatSecondaryAction}
              />
            </ConversationList>
          </>
        )}
      </ScrollContainer>
    </>
  )
}
