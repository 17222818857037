import { useGetMe } from '@api/account/hooks/use-get-me'
import { useAppSelector } from '@app/flow/hooks'
import { routes } from '@routes/flow/routes'
import { filterByFields } from '@shared/lib/filter-utils/filter-by-fields'
import { sortByDate } from '@shared/lib/sort-utils/sort-by-date'
import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { ScrollContainer } from 'ui'
import { selectAllConversations } from '../../entities/conversations/model/entity-adapter'
import { MessageItem } from './message-item'
import { ConversationType } from '@shared/types/conversation'

export interface DirectMessagesContentProps extends SpaceProps {
  searchString?: string
}

export const DirectMessagesContent: FC<DirectMessagesContentProps> = ({
  searchString,
  ...spaceProps
}) => {
  const conversations = selectAllConversations(useAppSelector((state) => state.conversations))
  const { meData } = useGetMe()
  const chats = conversations.filter((c) => c.conversationType === ConversationType.CHAT)

  const navigate = useNavigate()

  const filteredChats = useMemo(() => {
    return chats.filter(
      (chat) =>
        //returns all chats with last message and chat with "me"
        chat.lastMessage ||
        (chat.chatUsers.length === 1 &&
          chat.lastMessage &&
          chat.chatUsers.some((user) => user.user.userId === meData?.userId))
    )
  }, [chats, meData])

  // TODO: check if this search is needed at all
  const searchResults = useMemo(() => {
    // This ternary here to have sorting and filtering only once
    const filteredResults = searchString
      ? filterByFields(filteredChats, searchString, [
          'chatUsers[0].user.firstName',
          'chatUsers[0].user.lastName',
        ])
      : filteredChats

    return sortByDate(filteredResults, 'lastMessage.updatedAt', 'desc')
  }, [filteredChats, searchString])

  const onListCardItemClick = useCallback(
    (id: string) => {
      navigate(`../${routes.chats}/${id}`)
    },
    [navigate]
  )

  return (
    <ScrollContainer hideScrollbar mx="-2rem" {...spaceProps}>
      {searchResults.map((chat) => (
        <ListCardItem key={chat.id} onClick={() => onListCardItemClick(chat.id)}>
          <MessageItem chat={chat} />
        </ListCardItem>
      ))}
    </ScrollContainer>
  )
}

const ListCardItem = styled.div`
  padding: 1.2rem 2rem;
  cursor: pointer;
  &:not(:last-of-type) {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      height: 0.1rem;
      background: ${({ theme }) => theme.colors.background};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`
