import { MessageDetail } from '@api/messages/types/message-detail'
import { extractFiles } from '@shared/lib/media/extract-files'
import { getMetaData } from '@shared/lib/media/meta-data/get-meta-data'
import { processContentType } from '@shared/lib/media/mime-types'
import { v4 as uuid } from 'uuid'
import { AttachmentResponse } from '../../entities/messages/api/types/attachment-response'
import { SendMessageFn } from './model/types/send-message-props'

export const sendMessage = async ({
  conversationId,
  sendMessageAction,
  sendMessageApiFn,
  inputText,
  userId,
  createAttachment,
  fileList,
}: SendMessageFn) => {
  const clientMessageId = uuid()

  let uploadingAttachments: AttachmentResponse[] = []

  if (fileList && fileList.length > 0) {
    const promises = fileList.map(async (item) => {
      if (!item.file) {
        throw Error('No file')
      }
      const uri = item.dataURL
      const meta = await getMetaData(item.file)
      return { uri, meta }
    })

    const results = await Promise.all(promises)

    uploadingAttachments = results.map((item) => {
      return {
        uri: item.uri!,
        metadata: {
          contentType: item.meta.contentType,
          type: processContentType(item.meta.contentType),
          size: item.meta.size,
        },
        id: uuid(),
        name: item.meta.name,
        createdAt: new Date(),
        createdBy: '',
        lastModifiedAt: new Date(),
        lastModifiedBy: '',
      }
    })
  }

  const messageDetails: MessageDetail = {
    clientMessageId,
    chatId: conversationId,
    isDeleted: false,
    createdBy: userId,
    createdAt: new Date(),
    originalMessage: inputText,
    sourceLanguageCode: '',
    translations: [],
    reactions: [],
    updatedAt: new Date(),
    isSending: true,
    uploadingAttachments: uploadingAttachments,
  }

  sendMessageAction(messageDetails)

  let attachments: Array<{
    id: string
    uri: string
    uriExpiresAt: string
  }> = []

  if (fileList?.length) {
    const files = extractFiles(fileList)
    attachments = await createAttachment({ conversationId, files })
  }

  sendMessageApiFn({
    chatId: conversationId,
    clientMessageId,
    mediaIds: attachments.map(({ id }) => id),
    text: inputText,
  })
}
