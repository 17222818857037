import { MAX_FILES } from '@utils/variables'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageListType, ImageType } from 'react-images-uploading'
import { toast } from 'react-toastify'

type UseAttachments = () => [Array<ImageType>, (list: ImageListType) => void]

export const useAttachments: UseAttachments = () => {
  const [attachments, setAttachments] = useState<ImageListType>([])
  const { t } = useTranslation('attachments')

  const onAttachmentsChange = (imageList: ImageListType) => {
    if (imageList.length > MAX_FILES) {
      toast.error(`${t('maxFilesError', { count: MAX_FILES })}`)
      setAttachments(imageList.slice(0, MAX_FILES))
    } else {
      setAttachments(imageList)
    }
  }

  return [attachments, onAttachmentsChange]
}
