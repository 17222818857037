import { useGetAttachment } from '@api/chats/hooks/use-get-attachments'
import React, { FC, ReactElement } from 'react'
import { useInView } from 'react-intersection-observer'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { Container } from 'ui'

interface AttachmentFetcherProps extends AttachmentResponse {
  children: (
    options: AttachmentResponse & {
      src: string
      isProcessing: boolean
    }
  ) => ReactElement
  conversationId: string
  skip?: boolean
  width?: number
  height?: number
}
export const AttachmentFetcher: FC<AttachmentFetcherProps> = ({
  children,
  conversationId,
  skip,
  width,
  height,
  ...attachment
}) => {
  const { ref, inView } = useInView({ triggerOnce: true })

  const attachmentQuery = {
    attachmentId: attachment.id!,
    conversationId,
    inView,
    skip,
    ...(width && { width }),
    ...(height && { height }),
  }

  const { data: src, isLoading, isFetching } = useGetAttachment(attachmentQuery)

  //TODO: handle errors, show fallback
  if (!src && !isLoading && !isFetching && !skip) {
    return null
  }

  return (
    <>
      {/*<img src={src} style={{ position: 'fixed', top: 0, left: 0, zIndex: 9999999999999999999 }} />*/}
      <Container height="100%" ref={ref}>
        {children({
          src: src ?? attachment.uri,
          isProcessing: skip ? false : isFetching || isLoading,
          ...attachment,
        })}
      </Container>
    </>
  )
}
