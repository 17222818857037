import { GalleryModal } from '@modules/modals/gallery'
import { isExpectedType } from '@shared/lib/media/is-expected-type'
import { BaseUser } from '@shared/types/base-user'
import { MetadataType } from '@shared/types/meta-data'
import React from 'react'
import { ImageListType } from 'react-images-uploading'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { ModalVariant, useModal } from 'ui'

interface UseOpenGalleryModalProps {
  conversationId: string
  userId: string
  attachments: AttachmentResponse[] | ImageListType
  getSender: (userId: string) => (BaseUser & { doNotDisturbFrom: string }) | undefined
  createdAt?: Date
  skip?: boolean
}

export const useOpenGalleryModal = ({
  conversationId,
  userId,
  attachments,
  createdAt,
  getSender,
  skip,
}: UseOpenGalleryModalProps) => {
  const { openModal } = useModal()

  const mapValidAttachmentsWithIndexes = (
    attachments: AttachmentResponse[] | ImageListType
  ): { validAttachments: AttachmentResponse[]; originalIndexes: number[] } => {
    const validAttachments: AttachmentResponse[] = []
    const originalIndexes: number[] = []

    attachments.forEach((attachment, index) => {
      if ('file' in attachment) {
        const { file } = attachment

        if (file && (file.type.includes('image') || file.type.includes('video'))) {
          const type = file.type.includes('image') ? MetadataType.IMAGE : MetadataType.VIDEO
          const metadata = {
            size: file.size,
            contentType: file.type,
            type,
          }

          validAttachments.push({
            id: index.toString(),
            uri: URL.createObjectURL(file),
            name: file.name,
            createdAt: new Date(file.lastModified),
            createdBy: userId,
            lastModifiedAt: new Date(file.lastModified),
            lastModifiedBy: new Date(file.lastModified),
            metadata,
          })

          originalIndexes.push(index)
        }
      } else {
        if (isExpectedType(attachment.metadata, [MetadataType.IMAGE, MetadataType.VIDEO])) {
          validAttachments.push(attachment as AttachmentResponse)
          originalIndexes.push(index)
        }
      }
    })

    return { validAttachments, originalIndexes }
  }

  return (index: number) => {
    const sender = getSender(userId)
    const { validAttachments, originalIndexes } = mapValidAttachmentsWithIndexes(attachments)
    const adjustedIndex = originalIndexes.indexOf(index)

    if (adjustedIndex >= 0 && sender) {
      openModal({
        content: (
          <GalleryModal
            initialIndex={adjustedIndex}
            attachments={validAttachments}
            conversationId={conversationId}
            sender={sender}
            createdAt={createdAt}
            skip={skip}
          />
        ),
        variant: ModalVariant.FULL,
        backdropColor: 'rgba(31, 28, 29, 0.95)',
        hasCloseButton: false,
      })
    }
  }
}
