import { useIsTruncated } from '@hooks/use-is-truncated'
import React from 'react'
import { Container, StatusBadge, StatusVariant, Text, Tooltip } from 'ui'

export interface StatusLabelProps {
  label: string
  status: StatusVariant
  isPopup: boolean
}

export const StatusLabel: React.FC<StatusLabelProps> = (props) => {
  const { label, status, isPopup } = props
  const { isTruncated, textRef } = useIsTruncated()

  return (
    <Container display="flex" alignItems="center">
      <Tooltip text={label} disabled={!isTruncated}>
        <Text
          variant={isPopup ? 'textMedium' : 'smallTextRegular'}
          color={isPopup ? 'black' : 'greySuperDark'}
          isPopup={isPopup}
          label={label}
          status={status}
          lineClamp={1}
          wordBreak="break-all"
          ref={textRef}
        >
          {label}
        </Text>
      </Tooltip>
      <StatusBadge status={status} sizeLg={false} ml="0.4rem" />
    </Container>
  )
}
