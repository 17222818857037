import { Action } from '@reduxjs/toolkit'
import { Dispatch } from 'react'

export function dispatchWithPayload<P, T extends (payload: P) => Action<P>>(
  dispatch: Dispatch<Action<P>>,
  actionCreator: T
) {
  return (payload: P) => {
    dispatch(actionCreator(payload))
  }
}
