import { AVATAR_STALE_TIME } from '@hooks/constants'
import { useQuery } from '@tanstack/react-query'
import { getAttachment } from '../get-attachment'

export const ATTACHMENT = 'sending-attachment-fetcher'

interface UseGetAttachmentProps {
  attachmentId: string
  conversationId?: string
  inView?: boolean
  skip?: boolean
  height?: number
  width?: number
}

export const useGetAttachment = ({
  attachmentId,
  conversationId,
  inView,
  skip,
  height,
  width,
}: UseGetAttachmentProps) => {
  const isEnabled = Boolean(attachmentId && conversationId) && !skip && inView !== false

  return useQuery(
    [ATTACHMENT, attachmentId, width, height],
    () => getAttachment({ conversationId: conversationId!, attachmentId, width, height }),
    {
      staleTime: AVATAR_STALE_TIME,
      enabled: isEnabled,
    }
  )
}
