import { TFunction } from 'next-i18next'
import * as Yup from 'yup'
import { CreateChannelValues } from '.'
import { channelDescLimits, channelNameLimits } from '../variables'
import { ConversationType } from '@shared/types/conversation'

export const initialValues: CreateChannelValues = {
  name: '',
  desc: '',
  channelType: ConversationType.PUBLIC_CHANNEL,
}

export const validationSchema = (t: TFunction) => {
  const getErrorMessage = (min: number, max: number) =>
    t('errors.emptyField', { minLimit: min, maxLimit: max })

  return Yup.object().shape({
    name: Yup.string()
      .required(getErrorMessage(channelNameLimits.min, channelNameLimits.max))
      .max(channelNameLimits.max, getErrorMessage(channelNameLimits.min, channelNameLimits.max)),
    desc: Yup.string().max(
      channelDescLimits.max,
      t('errors.maxDescChars', { maxLimit: channelDescLimits.max })
    ),
    channelType: Yup.string(),
  })
}
