import { InviteChannelMembersValues } from '@components/invite-select/types'
import * as Yup from 'yup'

export const initialValues: InviteChannelMembersValues = {
  users: [],
}

export const validationSchema = Yup.object().shape({
  users: Yup.array().required().min(1),
})
