import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { Online } from '@api/chats/types/online'
import { BaseUser } from '@shared/types/base-user'
import { getStatusVariant } from '@utils/get-status'
import { isNativeChatsApp } from '@utils/is-native-chats-app'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'
import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Avatar, AvatarsSmIcon, Container, Text } from 'ui'

export interface ChipInputItemProps {
  value: string
  label: string
  avatarId?: string
  online?: Online
  color?: string
  avatarUri?: string
  multipleUsers?: BaseUser[]
  groupChatId?: string
}

export const ChipInputItem: FC<ChipInputItemProps> = ({
  label,
  avatarId,
  online,
  color,
  value,
  avatarUri,
  multipleUsers,
}) => {
  const theme = useTheme()

  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId: value,
    avatarId,
    isEnabled: !isNativeChatsApp(),
    width: AVATAR_PREVIEW_WIDTH.XS,
  })

  const isMultipleUsers = multipleUsers && multipleUsers?.length > 1

  return (
    <Container display="flex" alignItems="center">
      <Avatar
        icon={isMultipleUsers ? <AvatarsSmIcon /> : undefined}
        imgUrl={avatarUri || usersAvatarUrl}
        size="2.4rem"
        borderRadius="0.4rem"
        status={getStatusVariant(online)}
        label={isMultipleUsers ? '' : label}
        bgColor={`#${color}`}
        isAvatarExists={enabled}
      />
      <Text mx="0.8rem" color={theme.colors.black}>
        {label}
      </Text>
    </Container>
  )
}
