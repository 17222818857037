import styled from 'styled-components'
import { Caption, Container, Text } from 'ui'
import { ActionButtonProps, ListItemButtonProps, ListItemTextProps, ListProps } from '.'

export const ListContainer = styled(Container)`
  padding-bottom: 1.2rem;
  margin-top: 1.2rem;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.greyLight};
  &:last-child {
    border-bottom: 0;
  }
`

export const ListHeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.greyDark};
`

export const IconContainer = styled.span`
  display: flex;
`

export const ArrowBtn = styled.span<ListProps>`
  display: none;
  transform: rotate(${({ open }) => (open ? '0' : '180deg')});
`

export const ListHeaderIcon = styled(Container)`
  margin-left: 1.6rem;

  &:hover ${ArrowBtn} {
    display: flex;
  }

  &:hover ${IconContainer} {
    display: none;
  }
`

export const ListHeaderText = styled(Caption)`
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.greySuperDark};
  text-transform: uppercase;
`

export const ListItemButton = styled.button<ListItemButtonProps>`
  display: flex;
  align-items: center;
  height: 3.2rem;
  padding: 0.4rem 1.6rem;
  color: ${({ theme }) => theme.colors.greyLight};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.greyLight : '')};
  width: 100%;
  :hover {
    background-color: ${({ theme }) => theme.colors.greyLighter};
  }
  transition: color ${({ theme }) => theme.transitionTimes.short};
`

export const ListItemIcon = styled(Container)`
  margin-left: 1.6rem;
`

export const ListItemText = styled(Text)<ListItemTextProps>`
  font-weight: ${({ read }) => (read ? 400 : 500)};
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.black};
`
export const ListItemTextContainer = styled(Container)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 1.6rem;
`

export const PrimaryActionIconBtn = styled.span<ActionButtonProps>`
  transition: color ${({ theme }) => theme.transitionTimes.short};
  margin-right: 1.2rem;
  color: ${({ theme, isOpen }) => (isOpen ? theme.colors.primary : theme.colors.greyDark)};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const SecondaryActionIconBtn = styled.button`
  color: ${({ theme }) => theme.colors.greyDark};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const AddIconBtn = styled.button`
  display: flex;
`

export const ListItemsContainer = styled(Container)<ListProps>`
  margin-top: ${({ open }) => (open ? '0.8rem' : '0rem')};
`
