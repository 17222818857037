import { useAttachmentsUploadingContext } from '@contexts/attachments-uploading-provider'
import { DragEvent } from 'react'
import { useState, useCallback, useEffect } from 'react'

export const useDragAndDrop = () => {
  const {
    dragProps: { onDrop, onDragOver, onDragStart },
  } = useAttachmentsUploadingContext()

  const [isDragging, setIsDragging] = useState(false)
  const [dragCounter, setDragCounter] = useState(0)

  const handleDragEnter = useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (dragCounter === 0) {
        setIsDragging(true)
      }
      setDragCounter((prev) => prev + 1)
    },
    [dragCounter]
  )

  const handleDragLeave = useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setDragCounter((prev) => {
        const newCounter = prev - 1
        if (newCounter === 0) {
          setIsDragging(false)
        }
        return newCounter
      })
    },
    [dragCounter]
  )

  const handleDrop = useCallback(
    (e: DragEvent) => {
      setDragCounter(0)
      setIsDragging(false)
      onDrop?.(e)
    },
    [onDrop]
  )

  return {
    isDragging,
    dragHandlers: {
      onDragEnter: handleDragEnter,
      onDragLeave: handleDragLeave,
      onDrop: handleDrop,
      onDragOver,
      onDragStart,
    },
  }
}
