import { createEntityAdapter } from '@reduxjs/toolkit'
import { Channel } from '../api/types'

export const channelsEntityAdapter = createEntityAdapter<Channel>({
  selectId: (channel) => channel.id,
})

export const channelsInitialState = channelsEntityAdapter.getInitialState()

export const { selectAll: selectAllChannels, selectById: selectChannelById } =
  channelsEntityAdapter.getSelectors()
