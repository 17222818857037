import { Chat } from '@api/chats/types/chat'
import { ChatType, ConversationType } from '@shared/types/conversation'

// ConversationType helpers
export const isChat = (chat: Chat): boolean => chat.conversationType === ConversationType.CHAT

//TODO: Remove the check by name, currently only newly created workspaces have general channel with type GeneralChannel
export const isGeneralChannel = (chat: Chat): boolean =>
  chat.conversationType === ConversationType.GENERAL_CHANNEL || chat.name === '#general'

// ChatType helpers
export const isGeneralChannelChatType = (type: ChatType) => type === ChatType.GENERAL_CHANNEL
