import { getUsersAvatar } from '@api/account/get-users-avatar'
import { AVATAR_STALE_TIME } from '@hooks/constants'
import { useQuery } from '@tanstack/react-query'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'

export const USERS_AVATAR = 'users_avatar'

interface UseGetUsersAvatar {
  userId?: string | null
  avatarId?: string
  isEnabled?: Boolean
  width?: number
  height?: number
}

// const app = process.env.APP_ENV

export const useGetUsersAvatar = ({
  userId,
  avatarId,
  isEnabled = true,
  width = AVATAR_PREVIEW_WIDTH.SM,
  height,
}: UseGetUsersAvatar) => {
  const enabled = Boolean(avatarId && userId && isEnabled)
  const { data, isError, isLoading, error } = useQuery(
    [USERS_AVATAR, userId, avatarId, width, height],
    () => getUsersAvatar({ userId: userId!, width, height }),
    {
      refetchOnWindowFocus: false,
      staleTime: AVATAR_STALE_TIME,
      enabled,
    }
  )

  const usersAvatarUrl = data?.url || undefined

  return { usersAvatarUrl, isError, isLoading, error, enabled }
}
