import { ChipInputItem, ChipInputItemProps } from '@components/chip-input/chip-input-item'
import { Skeleton } from '@modules/skeleton'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  components,
  MultiValueRemoveProps,
  OptionProps,
  MultiValueGenericProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select'
import ReactSelect from 'react-select'
import { css, useTheme, DefaultTheme } from 'styled-components'
import styled from 'styled-components'
import { CloseIcon, Container, Text } from 'ui'

interface ChipInputProps {
  options: ChipInputItemProps[]
  isMulti: boolean
  defaultValue: ChipInputItemProps | null
  setOptionsList: Dispatch<SetStateAction<any[]>>
  onInputChange?: (value: string) => void
  isDisabled?: boolean
  setGroupChatIds?: Dispatch<SetStateAction<string[]>>
  helperText?: string
  maxValues?: number
}

type DefaultThemeColors = DefaultTheme['colors']

interface StyledInputContainerProps {
  hasError: boolean
  isFocused: boolean
}

export const ChipInput: FC<ChipInputProps> = ({
  options,
  isMulti,
  defaultValue,
  setOptionsList,
  onInputChange,
  setGroupChatIds,
  isDisabled,
  helperText,
  maxValues,
}) => {
  const appTheme = useTheme()
  const { t } = useTranslation('create-new')
  const [selectedCount, setSelectedCount] = useState<number>(0)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const hasReachedLimit = !!(maxValues && selectedCount >= maxValues)
  const showError = hasReachedLimit && searchValue.length > 0

  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...props}>
        <CloseIcon />
      </components.MultiValueRemove>
    )
  }

  const MultiValueLabel = (props: MultiValueGenericProps<ChipInputItemProps>) => {
    const { data } = props
    return (
      <components.MultiValueLabel {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          avatarId={data.avatar?.id}
          avatarUri={data?.avatarUri}
          multipleUsers={data?.multipleUsers}
        />
      </components.MultiValueLabel>
    )
  }

  const SingleValue = (props: SingleValueProps<ChipInputItemProps>) => {
    const { data } = props as { data: ChipInputItemProps }
    return (
      <components.SingleValue {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          //@ts-ignore
          avatarId={data.avatar?.id}
          avatarUri={data?.avatarUri}
        />
      </components.SingleValue>
    )
  }

  const Option = (props: OptionProps<ChipInputItemProps>) => {
    const { data } = props as { data: ChipInputItemProps }
    return (
      <components.Option {...props}>
        <ChipInputItem
          value={data.value}
          online={data.online}
          label={data.label}
          color={data.color}
          avatarId={data.avatar?.id}
          avatarUri={data?.avatarUri}
          multipleUsers={data?.multipleUsers}
        />
      </components.Option>
    )
  }

  const handleOnChange = (options: any) => {
    if (options) {
      const toSet: any = []
      options.forEach((option: any) => {
        if (option.multipleUsers?.length > 0) {
          toSet.push(...option.multipleUsers)
          setGroupChatIds?.((prev) => [...prev, option.groupChatId])
        } else {
          toSet.push(option)
        }
      })
      setOptionsList(toSet)
      setSelectedCount(toSet.length)
    } else {
      setSelectedCount(0)
    }
  }
  const handleOnInputChange = (value: string) => {
    setSearchValue(value)
    onInputChange?.(value)
  }

  if (isDisabled) return <Skeleton type="chipInput" />

  return (
    <StyledInputContainer isFocused={isFocused} hasError={showError}>
      <ReactSelect
        key={defaultValue ? `${defaultValue}-chip-input` : 'chip-input'}
        onInputChange={handleOnInputChange}
        options={options}
        isMulti={isMulti}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isSearchable
        isClearable={false}
        menuIsOpen={hasReachedLimit ? false : undefined}
        components={{
          DropdownIndicator: undefined,
          IndicatorSeparator: undefined,
          MultiValueRemove,
          MultiValueLabel,
          Option,
          SingleValue,
        }}
        defaultValue={defaultValue ?? []}
        onChange={handleOnChange}
        placeholder={t('toNameOrSurname')}
        styles={multiSelectStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: appTheme.colors.primary,
            neutral10: appTheme.colors.greyLight,
            neutral20: appTheme.colors.grey,
            neutral30: appTheme.colors.greyDark,
          },
        })}
        isOptionDisabled={() => hasReachedLimit}
      />
      {showError && (
        <Text p="0 1.2rem 1.2rem" color="red" variant="smallTextRegular">
          {helperText}
        </Text>
      )}
      {maxValues && selectedCount > 0 && (
        <Container position="absolute" right="1.2rem" top="1.3rem">
          <Text color="greyDark">
            {selectedCount}/{maxValues}
          </Text>
        </Container>
      )}
    </StyledInputContainer>
  )
}

const multiSelectStyles: StylesConfig<ChipInputItemProps, boolean> = {
  control: (base, props) => ({
    ...base,
    minHeight: '4.8rem',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0.8rem',
    overflowY: 'auto',
    cursor: 'pointer',
  }),
  multiValue: (base, props) => ({
    ...base,
    backgroundColor: props.theme.colors.neutral0,
    borderRadius: '0.8rem',
    margin: `0.4rem 0.8rem 0.4rem 0`,
    padding: '0.4rem 1.2rem',
    height: '3.2rem',
    display: 'flex',
    alignItems: 'center',
  }),
  multiValueLabel: (base) => ({
    ...base,
    paddingLeft: 0,
    fontSize: '1.5rem',
  }),
  multiValueRemove: (base) => ({
    ...base,
    'padding': 0,
    'flex': 0,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
    },
  }),
  placeholder: (base, props) => ({
    ...base,
    color: props.theme.colors.neutral30,
    margin: 'auto 0',
  }),
  container: (base) => ({
    ...base,
    fontSize: '1.5rem',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0.4rem 4rem 0.4rem 1.2rem',
  }),
  option: (base, props) => ({
    ...base,
    'padding': '0.8rem 1.2rem',
    'fontSize': '1.5rem',
    'letterSpacing': '-0.017em',
    'display': 'flex',
    'alignItems': 'center',
    'height': '4rem',
    'backgroundColor': props.isFocused ? props.theme.colors.neutral10 : 'transparent',
    'color': '#373234',
    ':not(:last-child)': {
      borderBottom: `1px solid ${props.theme.colors.neutral10}`,
    },
    ':hover': {
      cursor: 'pointer',
      backgroundColor: props.theme.colors.neutral10,
    },
  }),
  menu: (base, props) => ({
    ...base,
    border: `1px solid ${props.theme.colors.neutral20}`,
    borderRadius: '0.8rem',
    overflowY: 'auto',
    boxShadow: 'none',
    padding: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
}

const getBorderColor = ({
  colors,
  isFocused,
  hasError,
}: {
  colors: DefaultThemeColors
  isFocused: boolean
  hasError: boolean
}) => {
  if (hasError) return colors.red
  if (isFocused) return colors.primary
  return colors.grey
}

const StyledInputContainer = styled(Container)<StyledInputContainerProps>`
  position: relative;
  border-radius: 0.8rem;
  ${({ theme, isFocused, hasError }) => css`
    background-color: ${theme.colors.greyLight};
    transition: border-color ${theme.transitionTimes.short};
    border: 1px solid ${getBorderColor({ colors: theme.colors, isFocused, hasError })};
  `}
`
