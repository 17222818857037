import { PersonalProfile } from '@api/account/types/personal-profile'
import { ConversationType } from '@shared/types/conversation'
import React from 'react'
import { Conversation } from 'src/entities/conversations/model/conversation'
import { User } from 'src/entities/teammates/api/model/user-type'
import {
  Avatar,
  AvatarsLgIcon,
  AvatarsSmIcon,
  LockLgIcon,
  LockSmIcon,
  StatusVariant,
  TextLgIcon,
  TextSmIcon,
} from 'ui'
import { getRecipientOnlineStatus } from './utils'

interface GetIconProps extends Conversation {
  label?: string
  size: number
  iconSize?: number
  fontSize?: string
  showStatus?: boolean
  workspaceUsers?: User[]
  me?: PersonalProfile
  borderRadius?: string
  imgURL?: string
  isAvatarExists: boolean
}

export const getChatAvatar = ({
  conversationType,
  chatUsers,
  label,
  size,
  fontSize,
  color,
  workspaceUsers,
  borderRadius,
  me,
  imgURL,
  isAvatarExists,
}: GetIconProps) => {
  const usersCount = chatUsers?.length
  const getChatIconColor = () => {
    if (conversationType !== ConversationType.CHAT) return color
    if (!usersCount) return color
    if (usersCount == 2) {
      const notMe = chatUsers?.find(({ user }) => user.userId !== me?.userId)
      return notMe?.user.color
    }
    if (usersCount == 1) {
      return chatUsers?.[0]?.user.color
    }
    return color
  }

  const commonAvatarProps = {
    bgColor: `#${getChatIconColor()}`,
    borderRadius,
    size: `${size}px`,
    fontSize,
    isAvatarExists,
  }

  const getIcon = () => {
    if (conversationType === ConversationType.CHAT && usersCount && usersCount > 2) {
      return size > 24 ? <AvatarsLgIcon /> : <AvatarsSmIcon />
    }
    if (conversationType === ConversationType.PRIVATE_CHANNEL) {
      return size > 24 ? <LockLgIcon /> : <LockSmIcon />
    }
    return size > 24 ? <TextLgIcon /> : <TextSmIcon />
  }

  if (conversationType === ConversationType.CHAT && (!usersCount || usersCount <= 2)) {
    //TODO: we do not need to fetch users explicitly because we already have them in chatUsers with info about status NAT-1452
    const recipientOnlineStatus = getRecipientOnlineStatus(
      chatUsers,
      workspaceUsers || [],
      me || null
    )

    return (
      <Avatar
        {...commonAvatarProps}
        imgUrl={imgURL}
        status={recipientOnlineStatus === 0 ? StatusVariant.OFFLINE : StatusVariant.ONLINE}
        label={label}
      />
    )
  }

  return <Avatar {...commonAvatarProps} icon={getIcon()} />
}
