import { ChatsService } from '@api/chats/generated'

interface AttachmentRequest {
  conversationId: string
  attachmentId: string
  height?: number
  width?: number
}

export const getAttachment = ({ attachmentId, conversationId, height, width }: AttachmentRequest) =>
  ChatsService.getV3ChatsAttachments2(conversationId, attachmentId, height, width)
