import { getStatusVariant } from '@utils/get-status'
import { filter, mapValues } from 'lodash'
import React, { useState } from 'react'
import { FormatOptionLabelMeta } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, HelperText, Text } from 'ui'
import { InputLabel } from 'ui/src/components/input/text-field/labels'
import { ClearIndicator } from './clear-indicator'
import MembersAvatar from './members-avatar'
import { Menu } from './menu'
import { MultiValueRemove } from './multivalue-remove'
import { MenuOption, SelectStyles } from './styled'
import { OptionType, UserOptionValue, Value } from './types'
import { ValueContainer } from './value-container'

export interface InviteToChannelTextContent {
  noResultsMsg: string
  inChannel: string
}

export interface InviteToChannelTextFieldProps extends SpaceProps {
  label: string
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  disabled?: boolean
  helperText?: string
  value: Value
  onChange: (v: Value) => void
  loadOptions: OptionType[]
  maxMenuHeight?: number
  maxMultiChildrenAmount?: number
  texts: InviteToChannelTextContent
}

export interface MenuOptionProps {
  isDisabled: boolean
}

export const InviteToChannelSelect: React.FC<InviteToChannelTextFieldProps> = ({
  placeholder,
  label,
  helperText,
  disabled,
  type,
  value,
  onChange,
  loadOptions,
  maxMenuHeight = 210,
  maxMultiChildrenAmount,
  texts,
  ...spacing
}) => {
  const appTheme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const { noResultsMsg, inChannel } = texts

  // Filter by first character of the name or surname
  const startsWith = (value: UserOptionValue, inputValue: string) =>
    filter(mapValues(value, (v) => v.toLowerCase().startsWith(inputValue.toLowerCase()))).length > 0

  const filterUsers = (inputValue: string) => {
    return loadOptions.filter(({ value }) => startsWith(value, inputValue))
  }

  // State messages
  const noOptionsMessage = (obj: { inputValue: string }) => {
    if (obj.inputValue.trim().length === 0) {
      return null
    }
    return noResultsMsg
  }

  const promiseOptions = (inputValue: string) =>
    new Promise<OptionType[]>((resolve) => {
      setIsLoading(true)
      setTimeout(() => {
        resolve(filterUsers(inputValue))
        setIsLoading(false)
      }, 1000)
    })

  function formatOptionLabel(option: OptionType, { context }: FormatOptionLabelMeta<OptionType>) {
    const blockedInvite = option?.inChannel
    const status = getStatusVariant(option.online)

    if (context === 'menu') {
      return (
        <MenuOption isDisabled={blockedInvite}>
          <Container display="flex" alignItems="center" flexGap="0.8rem">
            <MembersAvatar option={option} status={status} />
            <Text mb="-0.3rem">{option.label}</Text>
          </Container>
          {blockedInvite && (
            <Text variant="smallTextRegular" mb="-0.5rem" letterSpacing="0.05em">
              {inChannel}
            </Text>
          )}
        </MenuOption>
      )
    }
    if (context === 'value') {
      return (
        <Container display="flex" alignItems="center" flexGap="0.8rem">
          <MembersAvatar option={option} status={status} />
          <Text mb="-0.3rem">{option.label}</Text>
        </Container>
      )
    }
    return null
  }

  return (
    <Container display="flex" flexDirection="column" {...spacing}>
      <InputLabel fontSize="1.2rem" labelText={label} />
      <AsyncSelect
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight}
        hideSelectedOptions
        onChange={(newValue) => {
          onChange(newValue as Value)
        }}
        closeMenuOnSelect
        isDisabled={disabled}
        isSearchable
        isClearable
        menuIsOpen={!isLoading && undefined}
        isMulti={true}
        styles={SelectStyles}
        noOptionsMessage={noOptionsMessage}
        formatOptionLabel={formatOptionLabel}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: appTheme.colors.primary,
            neutral10: appTheme.colors.background,
            neutral20: appTheme.colors.greyLight,
            neutral30: appTheme.colors.greyLighter,
            neutral40: appTheme.colors.grey,
            neutral50: appTheme.colors.greyDark,
            danger: appTheme.colors.red,
          },
        })}
        components={{
          Menu,
          IndicatorSeparator: undefined,
          DropdownIndicator: undefined,
          MultiValueRemove,
          ValueContainer,
          ClearIndicator,
        }}
        isOptionDisabled={(option) => option.inChannel}
        cacheOptions
        defaultOptions
        loadOptions={promiseOptions}
      />
      {Boolean(helperText) && <HelperText>{helperText}</HelperText>}
    </Container>
  )
}
