import { MessagesRepository } from '../repositories/messages-repository'
import { SyncMessagesData } from '../repositories/messages-repository'
import { MessagesRepositoryImplementation } from '../repositories/messages-repository-implementation'

class SyncMessagesService {
  constructor(private conversationsRepository: MessagesRepository) {}

  syncMessages({ pageSize, timeStamp, chatId, lastMessageId }: SyncMessagesData) {
    return this.conversationsRepository.syncMessages({ pageSize, timeStamp, chatId, lastMessageId })
  }
}

export const syncMessagesService = new SyncMessagesService(new MessagesRepositoryImplementation())
