import { useGetUsersAvatar } from '@api/account/hooks/use-get-users-avatar'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'
import React, { FC, useCallback, useState } from 'react'
import { User } from 'src/entities/teammates/api/model/user-type'
import { Action, TeammateActions } from 'src/features/teammate-actions'
import styled from 'styled-components'
import { Avatar, Container, StatusVariant } from 'ui'
import { TeammateInfo } from '../teammate-info'

export interface TeammatesListItemProps extends User {
  actions: Action[]
  onTeammateClick?: (userdId: string) => void
}

export const TeammatesListItem: FC<TeammatesListItemProps> = ({
  userId,
  firstName,
  lastName,
  jobTitle,
  joiningDate,
  color,
  avatar,
  online,
  actions,
  onTeammateClick,
}) => {
  const [quickActionsShown, setQuickActionsShown] = useState<boolean>(false)
  const { usersAvatarUrl, enabled } = useGetUsersAvatar({
    userId,
    avatarId: avatar?.id,
    width: AVATAR_PREVIEW_WIDTH.LG,
  })

  const onMouseEnter = useCallback(() => {
    setQuickActionsShown(true)
  }, [setQuickActionsShown])

  const onMouseLeave = useCallback(() => {
    setQuickActionsShown(false)
  }, [setQuickActionsShown])

  return (
    <ListItemContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ContentContainer flexGap="1.2rem" onClick={() => onTeammateClick?.(userId)}>
        <Avatar
          label={firstName}
          bgColor={`#${color}`}
          name={firstName}
          imgUrl={usersAvatarUrl}
          size="6.8rem"
          fontSize="3.6rem"
          isAvatarExists={enabled}
        />
        <TeammateInfo
          fullName={`${firstName} ${lastName}`}
          jobTitle={jobTitle}
          joined={joiningDate}
          status={online?.status === 1 ? StatusVariant.ONLINE : StatusVariant.OFFLINE}
        />
      </ContentContainer>
      {quickActionsShown && <TeammateActions actions={actions} />}
    </ListItemContainer>
  )
}

const ListItemContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const ContentContainer = styled(Container)`
  display: flex;
  flex: 1;
`
