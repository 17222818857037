import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, Heading, ImgContainer, Text } from 'ui'

interface Image {
  src: string
  width: number
  height: number
  alt?: string
}

interface EmptyResultsProps extends SpaceProps {
  title: string
  text?: string
  img?: Image
  innerSpacing?: string
}

const defaultImage: Image = {
  src: '/img/empty-results.svg',
  width: 250,
  height: 215,
}

export const EmptyResults: FC<EmptyResultsProps> = ({
  title,
  text,
  img,
  innerSpacing = '3.2rem',
  ...spacing
}) => {
  const { t } = useTranslation('common')

  const image = img || defaultImage
  return (
    <MainContainer gap={innerSpacing} {...spacing}>
      <ImgContainer>
        <img
          src={image.src}
          width={image.width}
          height={image.height}
          alt={image.alt || t('imgAlt.emptyResults')}
        />
      </ImgContainer>
      <Heading as="p" variant="h2">
        {title}
      </Heading>
      {text && <Text mt="0.8rem">{text}</Text>}
    </MainContainer>
  )
}

const MainContainer = styled(Container)<{ gap: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
  gap: ${({ gap }) => gap || '3.2rem'};
`
