import { useGetMyWorkspaces } from '@api/workspaces/hooks/use-get-my-workspaces'
import { ThemeTypeEnum, useTheme } from '@contexts/theme-provider'
import { useAuth } from '@hooks/use-auth'
import { routes } from '@routes/flow/routes'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Container, Heading, Text } from 'ui'

export const Forbidden = () => {
  const { setCurrentTheme } = useTheme()
  const { t } = useTranslation('forbidden')

  const { user, logout } = useAuth()

  const { data } = useGetMyWorkspaces({ enabled: true })

  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(routes.workspaces)
    //TODO remove this instruction after remove affect with setting current theme to dark
    setCurrentTheme(ThemeTypeEnum.LIGHT)
  }

  useEffect(() => {
    //TODO this effect should be removed
    setCurrentTheme(ThemeTypeEnum.DARK)
  }, [])

  return (
    <StyledContainer>
      <Heading variant="h0" mb="0.8rem">
        {t('title')}
      </Heading>
      <Text mb="4rem">{t('subtitle', { email: user?.profile.email })}</Text>
      <ButtonContainer>
        {data && data.length > 0 && (
          <Button onClick={handleNavigate} minWidth="20rem" variant="tertiary">
            {t('otherSpaces')}
          </Button>
        )}
        <Button onClick={logout} minWidth="20rem">
          {t('anotherEmail')}
        </Button>
      </ButtonContainer>
    </StyledContainer>
  )
}

const ButtonContainer = styled(Container)`
  display: flex;
  gap: 1.2rem;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/img/forbidden.svg') no-repeat;
  background-size: cover;
  background-position: center -6rem;
  height: 100%;
  text-align: center;
  padding-top: 14.5vh;
`
