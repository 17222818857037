import { PersonalProfile } from '@api/account/types/personal-profile'
import { CreateNewChatButton } from '@components/create-new-chat-btn'
import { SidebarHeaderSkeleton } from '@modules/skeleton/skeleton-variants/sidebar-header'
import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { withSkeleton } from 'src/HOC/with-skeleton'
import styled from 'styled-components'
import { SpaceProps } from 'styled-system'
import { Container, ArrowIndicator, ImgContainer, Popover, usePopover, Heading } from 'ui'
import { SIDEBAR_POPOVER_ID } from '.'
import { SidebarPopper, SidebarPopperAction } from './popper'

export interface SidebarHeaderProps extends SpaceProps {
  title: string
  subtitle: string | ReactNode
  avatarComponent: ReactNode
  popperItems: SidebarPopperAction[]
  me?: PersonalProfile
  onCreateNew?: VoidFunction
  popperComponent?: ReactNode
}

export interface ArrowIndicatorStylesProps {
  isCardOpen: boolean
}

export const SidebarHeader: FC<SidebarHeaderProps> = ({
  me,
  onCreateNew,
  title,
  subtitle,
  avatarComponent,
  popperItems,
  popperComponent,
  ...spaceProps
}) => {
  const { t } = useTranslation(['sidebar', 'common'])
  const { isOpen } = usePopover(SIDEBAR_POPOVER_ID)

  return (
    <Container {...spaceProps}>
      <Container display="flex" alignItems="center" height="4.8rem">
        {avatarComponent}
        <Container mx="1rem" flex="1 1 auto" maxWidth="15rem">
          <Popover
            popoverId={SIDEBAR_POPOVER_ID}
            placement="bottom-start"
            popperTrigger={
              <Container as="span" display="flex" alignItems="center" color="text">
                <WorkspaceName as="span" variant="h3" data-cy="workspaceMenuBtn">
                  {title}
                </WorkspaceName>
                <ArrowIndicator
                  open={isOpen}
                  hiddenLabel={t('hiddenLabel.mainMenu', { ns: 'common' })}
                />
              </Container>
            }
          >
            <SidebarPopper onClose={close} items={popperItems} headingComponent={popperComponent} />
          </Popover>
          {subtitle}
        </Container>
        {onCreateNew && <CreateNewChatButton onClick={onCreateNew} />}
      </Container>
    </Container>
  )
}

export const SidebarHeaderWithSkeleton = withSkeleton(SidebarHeader, <SidebarHeaderSkeleton />)

export const WorkspaceName = styled(Heading)`
  max-width: 13rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Logo = styled(ImgContainer)`
  flex: 0 0 auto;
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 0.8rem;
  overflow: hidden;
`
