import { getStatusVariant } from '@utils/get-status'
import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatConversationName } from 'src/entities/conversations/lib/format-conversation-name'
import { getConversationUserNames } from 'src/entities/conversations/lib/get-conversation-users-names'
import { Conversation } from 'src/entities/conversations/model/conversation'
import { ConversationType } from 'src/entities/conversations/model/conversation-type'
import styled, { useTheme } from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Avatar, AvatarsSmIcon, EditIcon, Text, Tooltip, TextSmIcon } from 'ui'

const hasDraftMessage = false
const size = '2.4rem'
const borderRadius = '0.4rem'
const iconSize = 24

interface ConversationItemProps {
  item: Conversation
  myUserId?: string
}

export interface ListItemTextProps {
  hasUnreadMessage?: boolean
  textRef?: RefObject<HTMLParagraphElement>
}

export interface ListProps {
  open: boolean
}

export const ConversationItem: FC<ConversationItemProps> = ({ item, myUserId }) => {
  const { chatUsers, unreadMessagesCount, color } = item
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)
  const { t } = useTranslation('sidebar')
  const { t: tChat } = useTranslation('chat')
  const chatUserNames = getConversationUserNames(
    tChat('chatName.deactivatedAccount'),
    chatUsers,
    myUserId
  )
  const theme = useTheme()
  const isChat = item.conversationType === ConversationType.CHAT

  const chatName = useMemo(() => {
    if (!item || !myUserId) return 'N/A'
    return formatConversationName(item, myUserId, tChat)
  }, [item, tChat])

  useEffect(() => {
    textRef?.current && setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth)
  }, [textRef])

  const shouldShowTooltip = isChat ? item.chatUsers.length >= 4 || isTruncated : isTruncated
  const tooltipText = isChat ? chatUserNames : chatName

  const hasUnreadMessage = unreadMessagesCount > 0

  const conversationItemAvatar = useMemo(() => {
    if (!isChat) {
      return (
        <Avatar
          bgColor={`#${color}`}
          borderRadius={borderRadius}
          size={size}
          icon={<TextSmIcon />}
        />
      )
    }
    if (chatUsers.length === 2) {
      const otherUser = chatUsers.find((user) => user.user.userId !== myUserId)
      if (otherUser) {
        const {
          user: {
            color: otherUserColor,
            firstName,
            avatar: { mediaUri },
            online,
            isDeleted,
          },
        } = otherUser

        return (
          <Avatar
            bgColor={`#${otherUserColor}`}
            borderRadius={borderRadius}
            isDeleted={isDeleted}
            size={size}
            label={firstName}
            imgUrl={mediaUri}
            status={getStatusVariant(online)}
          />
        )
      }
    }

    return (
      <Avatar
        bgColor={`#${color}`}
        borderRadius={borderRadius}
        size={size}
        icon={<AvatarsSmIcon />}
      />
    )
  }, [chatUsers])

  return (
    <>
      {conversationItemAvatar}
      <Tooltip text={tooltipText} placement="right" disabled={!shouldShowTooltip}>
        <ListItemText ml="0.8rem" ref={textRef}>
          {chatName}
        </ListItemText>
      </Tooltip>
      {(hasDraftMessage || item.unreadMessagesCount > 0) && (
        <Indicators ml="auto">
          {hasDraftMessage && (
            <EditIcon
              fill={theme.colors.greyDark}
              width={16}
              height={16}
              aria-label={t('draftMessage')}
            />
          )}
          {hasUnreadMessage && <NewMessageIndicator aria-label={t('unreadMessageAlt')} />}
        </Indicators>
      )}
    </>
  )
}

export const ListItemText = styled(Text)<ListItemTextProps>`
  text-align: left;
  font-weight: ${({ hasUnreadMessage }) => (hasUnreadMessage ? 500 : 400)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
  color: ${({ theme }) => theme.colors.black};
`

export const NewMessageIndicator = styled.span`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 10rem;
  background-color: ${({ theme }) => theme.colors.primary};
`

export const Indicators = styled.span<SpaceProps>`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  ${space}
`
