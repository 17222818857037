import { Chat } from '@api/chats/types/chat'
import { ChatUser } from '@api/chats/types/chat-user'
import { ProviderTranslation } from '@api/chats/types/provider-translation'
import { Translation } from '@api/chats/types/translation'
import { AttachmentResponse } from '@api/messages/types/attachment-response'
import {
  ChatResponse,
  ChatUserResponse,
  MediaModel,
  MessageTranslationDto,
  MessageTranslationItemDto,
  UserResponse,
} from '@native-chats-api/chats/generated'
import { ChatType } from '../entities'
import { ConversationType } from '@shared/types/conversation'

export const convertChatsListResponseToConversationList = (chat: ChatResponse): Chat => {
  const outputChat: Chat = {
    id: chat.id!,
    name: chat.name!,
    description: '', //need to check if we can avoid this field
    color: chat.color!,
    conversationType:
      chat.chatType === ChatType._0 ? ConversationType.CHAT : ConversationType.PUBLIC_CHANNEL,
    isMuted: chat.isMuted!,
    isBlocked: chat.isBlocked!,
    avatar: {
      mediaId: chat.avatar?.mediaId!,
      mediaUri: chat.avatar?.mediaUri!,
      mediaUriExpiresAt: new Date(chat.avatar?.mediaUriExpiresAt!),
    },
    createdAt: new Date(chat.createdAt!),
    chatUsers: chat.chatUsers?.map(mapChatUsers) ?? [],
    lastMessage: {
      messageId: chat.lastMessage?.messageId!,
      chatId: chat.lastMessage?.chatId!,
      isDeleted: chat.lastMessage?.isDeleted!,
      createdBy: chat.lastMessage?.createdBy!,
      createdAt: new Date(chat.lastMessage?.createdAt!),
      updatedAt: new Date(chat.lastMessage?.createdAt!),
      reactions: undefined, //chat.lastMessage?.reactions!,
      // reactions?: Reaction[]
      originalMessage: chat.lastMessage?.originalMessage!,
      sourceLanguageCode: chat.lastMessage?.sourceLanguageCode!,
      clientMessageId: chat.lastMessage?.clientMessageId!,
      translations: chat.lastMessage?.translations?.map(mapTranslation) ?? [],
      media: chat.lastMessage?.media?.map(mapMedia) ?? [],
    },
    messagesDeletedToMessageId: chat.messagesDeletedToMessageId!,
    unreadMessagesCount: chat.unreadMessagesCount!,
    createdBy: '', //need to check if we can avoid this field
    isDeleted: false,
  }
  return outputChat
}

const mapChatUsers = (chatUser: ChatUserResponse): ChatUser => {
  const user: UserResponse = chatUser.user!

  const outputChatUser: ChatUser = {
    user: {
      avatar: {
        mediaId: user.avatar?.mediaId!,
        mediaUri: user.avatar?.mediaUri!,
        mediaUriExpiresAt: user.avatar?.mediaUriExpiresAt!,
      },
      color: user.color!,
      doNotDisturbFrom: false,
      email: user.email!,
      firstName: user.firstName!,
      isDeleted: user.isDeleted!,
      jobTitle: '',
      languages: [],
      lastName: user.lastName!,
      online: {
        status: user.online?.status!,
        lastOnline: new Date(user.online?.lastOnline!),
      },
      phoneNumber: user.phoneNumber!,
      primaryLanguageCode: '',
      timeZone: {
        id: '',
        displayName: '',
      },
      userId: user.userId!,
      joiningDate: new Date(),
    },
    lastMessageReadAt: new Date(chatUser.lastMessageReadAt!),
    idOfLastMessageRead: chatUser.idOfLastMessageRead!,
    isOwner: chatUser.isOwner!,
    leftChat: chatUser.leftChat!,
  }
  return outputChatUser
}

export const mapMedia = (medium: MediaModel): AttachmentResponse => ({
  id: medium.mediaId || '',
  createdAt: new Date(), // following properties are not provided by BE (createdAt,createdBy, name, metadata)
  createdBy: '',
  uri: medium.mediaUri || '',
  name: '',
  metadata: {
    size: 0,
    contentType: '',
  },
})

const mapTranslation = (translation: MessageTranslationDto): Translation => {
  const outputTranslation: Translation = {
    translationId: translation.translationId!,
    languageCode: translation.languageCode!,
    isPrimaryLanguage: translation.isPrimaryLanguage!,
    translations: translation.translations?.map(mapProviderTranslation) ?? [],
    hasBeenReportedAsIncorrect: translation.hasBeenReportedAsIncorrect!,
  }
  return outputTranslation
}

const mapProviderTranslation = (
  translationProvider: MessageTranslationItemDto
): ProviderTranslation => {
  const outputProviderTranslation: ProviderTranslation = {
    provider: translationProvider.provider!,
    translation: translationProvider.translation!,
    isPreferred: translationProvider.isPreferred!,
  }
  return outputProviderTranslation
}
