import { getAttachment } from '@api/chats/get-attachment'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

export const useFileDownload = () => {
  const [downloading, setDownloading] = useState<{ [key: string]: boolean }>({})

  const downloadFile = useCallback(
    async (attachmentId: string, conversationId: string, filename: string, errorMsg: string) => {
      setDownloading((prev) => ({ ...prev, [attachmentId]: true }))

      try {
        const uri = await getAttachment({ conversationId, attachmentId })
        if (!uri) {
          throw new Error('Failed to get attachment URI')
        }

        const response = await fetch(uri)
        if (!response.ok) {
          throw new Error('Failed to download file')
        }

        const blob = await response.blob()
        const blobUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = blobUrl
        link.download = filename
        link.click()

        URL.revokeObjectURL(blobUrl)
      } catch (_err) {
        toast.error(errorMsg)
      } finally {
        setDownloading((prev) => {
          const newState = { ...prev }
          delete newState[attachmentId]
          return newState
        })
      }
    },
    []
  )

  const isDownloading = (attachmentId: string) => downloading[attachmentId] || false

  return { downloadFile, isDownloading }
}
