import { VideoIndicator } from '@components/video-indicator'
import React, { FC } from 'react'
import styled from 'styled-components'
import { Container, Text } from 'ui'
import { MediaContent } from './media-content'

interface MediaAttachmentProps {
  src: string
  name: string
  isVideo: boolean
  isLoading: boolean
  isIncoming: boolean
  onClick: () => void
  showOverlay: boolean
  overlayText?: string
}

export const MediaAttachment: FC<MediaAttachmentProps> = ({
  src,
  name,
  isVideo,
  isLoading,
  isIncoming,
  onClick,
  showOverlay,
  overlayText,
}) => {
  return (
    <AttachmentContainer role="button" onClick={onClick} isIncoming={isIncoming}>
      <MediaContent isIncoming={isIncoming} isLoading={isLoading} name={name} src={src} />
      {isVideo && <VideoIndicator />}
      {showOverlay && overlayText && (
        <AttachmentOverlay>
          <Text color="white">{overlayText}</Text>
        </AttachmentOverlay>
      )}
    </AttachmentContainer>
  )
}

export const AttachmentContainer = styled(Container)<{ isIncoming: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  background: ${({ isIncoming, theme }) => (isIncoming ? theme.colors.greyLight : '#4875C3')};

  & > img,
  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const AttachmentOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
`
