import { ME } from '@api/account/tags'
import { PatchMeArgs } from '@api/account/types/patch-me-args'
import { CHAT_LIST } from '@api/chats/query-keys'
import { WORKSPACE } from '@api/workspaces/tags'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { invalidateManyQueries } from '@utils/invalidate-many-queries'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { CONVERSATIONS_QUERY_KEY } from 'src/entities/conversations/api/use-sync-and-get-conversations'
import { userService } from 'src/features/user/repositories/user-service'
import { USER_LIST, USERS } from '../../../entities/teammates/api/hooks/use-get-users'

interface usePatchMeProps {
  disableToast?: boolean
  onSuccessCallback?: VoidFunction
}

const KeysToInvalidate = [ME, CHAT_LIST, USERS, USER_LIST]

export const usePatchMe = ({ disableToast = false, onSuccessCallback }: usePatchMeProps = {}) => {
  const { t } = useTranslation('common')
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (personalValues: PatchMeArgs) => userService.updateMyProfile(personalValues),
    onMutate: async (newMeData) => {
      await queryClient.cancelQueries([ME])
      const previousMeData = queryClient.getQueryData([ME])
      queryClient.setQueryData([ME, WORKSPACE], newMeData)
      return { previousMeData }
    },
    onError: (_e, _v, context) => {
      if (!disableToast) {
        toast.error(`${t('changesNotSaved')}.`)
      }
      queryClient.setQueryData([ME, WORKSPACE], context?.previousMeData)
    },
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback()
      }
      if (!disableToast) {
        toast.success(`${t('changesSaved')}.`)
      }
      queryClient.invalidateQueries([CONVERSATIONS_QUERY_KEY])
    },
    onSettled: () => {
      invalidateManyQueries(queryClient, KeysToInvalidate)
    },
  })
}
