import React, { Suspense, lazy } from 'react'
import { Authenticator } from '@api/auth/oauth/auth-service'
import { authConfig } from '@api/auth/oauth/oauth-configuration'

export const authenticator = new Authenticator(authConfig)

export enum Apps {
  Flow = 'flow',
  Chats = 'chats',
}

const NativeFlowApp = lazy(() => import('./native-flow-app'))

export const App: React.FC = () => {

  return (
    <Suspense fallback={null}>
      <NativeFlowApp />
    </Suspense>
  )
}
