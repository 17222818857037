import { CheckIcon } from '@components/icons'
import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, Text } from 'ui'
import { TranslationBox, SelectedIndicator } from './styled'

export interface TranslationTextProps {
  text: string
  isSelected?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

export const TranslationText: FC<TranslationTextProps> = ({ text, isSelected, onClick }) => {
  const { t } = useTranslation('translation-picker')
  const textBlockRef = useRef<HTMLParagraphElement | null>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const [isUnwrapped, setIsUnwrapped] = useState(false)

  useEffect(() => {
    const element = textBlockRef.current
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight)
    }
  }, [text])

  const handleUnwrap = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsUnwrapped(!isUnwrapped)
  }

  return (
    <TranslationBox isSelected={isSelected} text={text} onClick={onClick}>
      {isSelected && (
        <SelectedIndicator>
          <CheckIcon aria-label={t('selectedTranslationAlt')} />
        </SelectedIndicator>
      )}
      <Container>
        <TranslationTextBlock isUnwrapped={isUnwrapped} ref={textBlockRef}>
          {text}
        </TranslationTextBlock>
        {isTruncated && (
          <MoreIndicator onClick={handleUnwrap}>
            {isUnwrapped ? t('hideTextIndicator') : t('moreTextIndicator')}
          </MoreIndicator>
        )}
      </Container>
    </TranslationBox>
  )
}

const TranslationTextBlock = styled(Text)<{ isUnwrapped: boolean }>`
  max-width: 22.8rem;
  display: -webkit-box;
  -webkit-line-clamp: ${({ isUnwrapped }) => (isUnwrapped ? 'unset' : '5')};
  -webkit-box-orient: vertical;
  overflow: ${({ isUnwrapped }) => (isUnwrapped ? 'visible' : 'hidden')};
  text-overflow: ${({ isUnwrapped }) => (isUnwrapped ? 'unset' : 'ellipsis')};
`

const MoreIndicator = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  background: none;
  line-height: 1.467;
  cursor: pointer;
`
