import { Action } from '@components/action'
import { useAttachmentsUploadingContext } from '@contexts/attachments-uploading-provider'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachIcon } from 'ui'

export const AttachmentButton: FC = () => {
  const { onImageUpload, lengthError } = useAttachmentsUploadingContext()

  const { t } = useTranslation('attachments')

  return (
    <Action
      icon={<AttachIcon />}
      onClick={onImageUpload}
      name={t('uploadAttachments')}
      disabled={lengthError}
      color="light"
    />
  )
}
