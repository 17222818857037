import { createAttachment } from '@api/messages/create-attachment'
import { uploadBlob } from '@modules/modals/edit-avatar/utils/crop-utils'
import { processArray } from '@shared/lib/async/map-array-async'
import { getMetaData } from '@shared/lib/media/meta-data/get-meta-data'
import { useMutation } from '@tanstack/react-query'

export interface SendAttachmentRequest {
  conversationId: string
  files: File[]
}

export type CreateAttachmentResponse = {
  id: string
  uri: string
  uriExpiresAt: string
}

const sendAttachment = async ({
  conversationId,
  files,
}: {
  conversationId: string
  files: File[]
}) => {
  const requestBody = await processArray(files, async (file) => {
    return await getMetaData(file)
  })
  const data = await createAttachment(conversationId, requestBody)
  const attachmentsArray = files.map((file, index) => ({
    file,
    meta: data[index],
  }))
  await Promise.all(
    attachmentsArray.map((attachment) => {
      if (!attachment.meta.uri) {
        throw Error('wrong attachment format')
      }
      return uploadBlob(attachment.meta.uri, attachment.file, attachment.file.type)
    })
  )
  //@ts-ignore - wrong type in swagger
  return data as CreateAttachmentResponse[]
}

interface CreateAttachmentCallbacks {
  onSuccess?: (data: CreateAttachmentResponse[]) => void
}

export const useCreateAttachment = (callbacks?: CreateAttachmentCallbacks) => {
  return useMutation<CreateAttachmentResponse[], Error, SendAttachmentRequest>({
    mutationFn: ({ conversationId, files }) => sendAttachment({ conversationId, files }),
    onSuccess: callbacks?.onSuccess,
  })
}
