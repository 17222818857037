import { accountApi } from './instance'

interface GetUsersAvatar {
  userId: string
  width?: number
  height?: number
}

export const getUsersAvatar = async ({ userId, width, height }: GetUsersAvatar) => {
  const params = {
    ...(width && { width }),
    ...(height && { height }),
  }
  const { data } = await accountApi.get(`/users/${userId}/avatar`, {
    params,
  })

  return data
}
