import { ChatType, ConversationType } from "@shared/types/conversation"

export interface Channel {
  id: string
  avatar: string
  color: string
  isMuted: boolean
  name: string
  type: ChatType
  membersCount: number
  joined: boolean
}

export const PUBLIC_CHANNEL_TYPES = [ChatType.PUBLIC_CHANNEL, ChatType.GENERAL_CHANNEL]

export const CHANNEL_TYPES = [
  ConversationType.PRIVATE_CHANNEL,
  ConversationType.PUBLIC_CHANNEL,
  ConversationType.GENERAL_CHANNEL,
]
