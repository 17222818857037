import { useGetMe } from '@api/account/hooks/use-get-me'
import { NetworkMode } from '@api/enums'
import { useAppDispatch } from '@app/flow/hooks'
import { useMutation } from '@tanstack/react-query'
import { updateConversation } from 'src/entities/conversations/model/slice'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { resendMessage, setMessageError, setMessageSent } from 'src/entities/messages/model/slice'
import { sendMessage as sendMessageAction } from 'src/entities/messages/model/slice'
import { chatApi } from 'src/features/chats/repositories/chat-service'
import { SendMessageProps } from '../send-message'
import { Message } from '../types/message'

export const MESSAGE_MUTATION = 'message'

interface SendMessageCallbacks {
  onMutateCallback?: CallableFunction
  onErrorCallback?: CallableFunction
  onSuccessCallback?: CallableFunction
}

export const useSendMessage = ({
  onMutateCallback,
  onErrorCallback,
  onSuccessCallback,
}: SendMessageCallbacks = {}) => {
  const dispatch = useAppDispatch()
  const { meData } = useGetMe()
  return useMutation<Message, Error, SendMessageProps>(
    [MESSAGE_MUTATION],
    ({ chatId, clientMessageId, mediaIds, text }) =>
      chatApi.sendMessage({ chatId, clientMessageId, mediaIds, text }),
    {
      networkMode: NetworkMode.OFFLINE_FIRST,
      onMutate: (args) => {
        const messageDetails = {
          clientMessageId: args.clientMessageId,
          chatId: args.chatId,
          isDeleted: false,
          createdBy: meData?.userId ?? '',
          createdAt: new Date(),
          originalMessage: args.text,
          sourceLanguageCode: '',
          translations: [],
          reactions: [],
          attachments: args.attachments,
          uploadingAttachments: args.uploadingAttachments,
        }
        if (args.isResending) {
          dispatch(resendMessage(messageDetails))
          return
        }
        onMutateCallback?.()
      },
      onError: (_error, args) => {
        dispatch(setMessageError(args))
        onErrorCallback?.()
      },
      onSuccess: (response, args) => {
        const timestamp = new Date()

        const lastMessageDetails = {
          clientMessageId: args.clientMessageId,
          chatId: args.chatId,
          messageId: response.id,
          isDeleted: false,
          createdBy: meData?.userId ?? '',
          createdAt: timestamp,
          updatedAt: timestamp,
          originalMessage: args.text,
          sourceLanguageCode: '',
          translations: [],
          reactions: [],
          attachments: args.attachments as AttachmentResponse[],
        }

        dispatch(sendMessageAction(lastMessageDetails))
        dispatch(
          updateConversation({
            id: args.chatId,
            updatedData: {
              lastMessage: lastMessageDetails,
            },
          })
        )
        dispatch(setMessageSent({ ...response, ...args }))
        onSuccessCallback?.()
      },
    }
  )
}
