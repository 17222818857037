import { Message } from '../model/message'
import { SyncMessagesData } from './repositories/messages-repository'
import { syncMessagesService } from './services/sync-messages-service'

type SyncData = {
  messages: Message[]
  lastMessageIdInBatch?: string
  lastMessageTimeStampInBatch?: string
  isSyncFinished?: boolean
}

export const syncMessages = async (
  { pageSize, timeStamp, chatId, lastMessageId }: SyncMessagesData,
  syncData: SyncData = {
    messages: [],
    lastMessageIdInBatch: '',
    lastMessageTimeStampInBatch: '',
    isSyncFinished: false,
  }
): Promise<{
  messages: Message[]
  lastMessageIdInBatch?: string
  lastMessageTimeStampInBatch?: string
  isSyncFinished?: boolean
}> => {
  const syncedData = await syncMessagesService.syncMessages({
    pageSize,
    timeStamp,
    chatId,
    lastMessageId,
  })

  const newSyncData: SyncData = {
    messages: [...syncData.messages, ...syncedData.messages],
    lastMessageIdInBatch: syncedData.lastMessageIdInBatch,
    lastMessageTimeStampInBatch: syncedData.lastMessageTimeStampInBatch,
    isSyncFinished: syncedData.isSyncFinished,
  }

  if (newSyncData.isSyncFinished) {
    return {
      messages: newSyncData.messages,
      lastMessageIdInBatch: newSyncData.lastMessageIdInBatch,
      lastMessageTimeStampInBatch: newSyncData.lastMessageTimeStampInBatch,
      isSyncFinished: newSyncData.isSyncFinished,
    }
  }

  if (!newSyncData.lastMessageIdInBatch) {
    throw new Error(
      'syncMessages: lastMessageIdInBatch is missing, cannot continue syncing messages.'
    )
  }

  return syncMessages(
    {
      pageSize,
      timeStamp: newSyncData.lastMessageTimeStampInBatch,
      chatId,
      lastMessageId: newSyncData.lastMessageIdInBatch,
    },
    newSyncData
  )
}
