import { DateDivider } from '@components/date-divider'
import { GeneralMessageProps, Message, MessageVariant } from '@components/message'
import { useInterfaceLanguage } from '@hooks/use-interface-language'
import { getLocaleDate } from '@shared/lib/get-locale-date'
import { Language } from '@utils/locales'
import React, { FC } from 'react'
import { Container } from 'ui'
import { MessageDate } from '../../../model/types/types'

interface MessageListWithDateDividerProps {
  list: (GeneralMessageProps | MessageDate)[]
  showAvatar?: boolean
}

export const MessageListWithDateDivider: FC<MessageListWithDateDividerProps> = ({
  list,
  showAvatar,
}) => {
  const { interfaceLanguage } = useInterfaceLanguage()

  return (
    <div style={{ overflowY: 'auto', height: '100%' }}>
      {list.map((message: GeneralMessageProps | MessageDate, index) => {
        if (message.type === 'day' && message.date) {
          const formattedDate = getLocaleDate(message.date, interfaceLanguage.value as Language)
          return <DateDivider key={index} date={formattedDate} m="0.8rem auto" />
        } else {
          const castedMessage = message as GeneralMessageProps
          const variant = castedMessage.isError
            ? MessageVariant.NOT_SENT
            : castedMessage?.isSending
            ? MessageVariant.IS_SENDING
            : castedMessage?.isDeleted
            ? MessageVariant.DELETED
            : MessageVariant.DEFAULT
          return (
            <Container key={castedMessage.clientMessageId} my="0.8rem">
              <Message {...castedMessage} variant={variant} hasAvatar={showAvatar} />
            </Container>
          )
        }
      })}
    </div>
  )
}
