import { useGetMe } from '@api/account/hooks/use-get-me'
import { SidebarConversation } from '@components/chat-list-item'
import { CollapsibleList, PrimaryAction } from '@components/collapsible-list'
import { DirectMessageIcon, HashTagIcon, TeammatesIcon, UnreadIcon } from '@components/icons'
import { useLeftSideBar } from '@hooks/use-left-side-bar'
import { CreateChannelModal } from '@modules/modals/create-channel'
import { SidebarContentSkeleton } from '@modules/skeleton/skeleton-variants/sidebar-content'
import { routes } from '@routes/flow/routes'
import { notificationsManager } from '@utils/notifications'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { formatConversationName } from 'src/entities/conversations/lib/format-conversation-name'
import { getConversationUserNames } from 'src/entities/conversations/lib/get-conversation-users-names'
import { sortByAlphabet } from 'src/shared/lib/sort-utils/sort-by-alphabet'
import { sortByDate } from 'src/shared/lib/sort-utils/sort-by-date'
import { ListIcon, PlusIcon, PopperAction, ScrollContainer, useModal, VerticalMenuIcon } from 'ui'
import { useSyncAndGetConversations } from '../../entities/conversations/api/use-sync-and-get-conversations'
import { ConversationType } from '../../entities/conversations/model/conversation-type'

export const SidebarContent: FC = () => {
  const { setSelectedOption } = useLeftSideBar()
  const navigate = useNavigate()
  const { openModal } = useModal()
  const { channelId, chatId } = useParams()
  const { t } = useTranslation('sidebar')
  const { t: tChat } = useTranslation('chat')
  const { meData } = useGetMe()
  const { conversations, isLoading } = useSyncAndGetConversations()

  const conversationsWithFormattedName: SidebarConversation[] = useMemo(() => {
    return conversations.map((conversation) => ({
      ...conversation,
      name: formatConversationName(conversation, meData?.userId!, tChat),
      chatUserNames: getConversationUserNames(
        tChat('chatName.deactivatedAccount'),
        conversation.chatUsers,
        meData?.userId
      ),
    }))
  }, [conversations, tChat])

  const myActiveChannels = useMemo(() => {
    return conversationsWithFormattedName.filter(({ chatUsers, conversationType }) => {
      if (conversationType === ConversationType.CHAT) return false
      return chatUsers.some((user) => user.user.userId === meData?.userId && !user.leftChat)
    })
  }, [conversationsWithFormattedName])

  const sortedChannels = useMemo(() => {
    return sortByAlphabet(myActiveChannels, 'name', 'asc')
  }, [conversationsWithFormattedName])

  // Returns all conversation  of type Chat and chat with "me"
  // Chat is created after the first message is sent so we don't need to check lastMessage
  const sortedChats = useMemo(() => {
    return sortByAlphabet(
      conversationsWithFormattedName.filter((c) => {
        const isChat = c.conversationType === ConversationType.CHAT
        const isPersonalConversation =
          isChat &&
          c.chatUsers.length === 1 &&
          c.chatUsers.some((user) => user.user.userId === meData?.userId)

        return isChat || isPersonalConversation
      }),
      'name',
      'asc'
    )
  }, [conversationsWithFormattedName])

  useEffect(() => {
    setSelectedOption(channelId ?? chatId ?? null)
  }, [channelId, chatId, setSelectedOption])

  const onCreateChannelClick = useCallback(() => {
    openModal({ content: <CreateChannelModal /> })
  }, [openModal])

  const onCreateChatClick = useCallback(() => {
    navigate(`${routes.chats}/${routes.createNew}`)
  }, [navigate])

  const onChannelClick = useCallback(
    (id: string) => {
      navigate(`${routes.channels}/${id}`)
    },
    [navigate]
  )

  const onChatClick = useCallback(
    (id: string) => {
      navigate(`${routes.chats}/${id}`)
    },
    [navigate]
  )

  const onTeammatesClick = useCallback(() => {
    navigate(routes.teammates)
  }, [navigate])

  const onDirectMessageClick = useCallback(() => {
    navigate(routes.directMessages)
  }, [navigate])

  const sortedUnreadMessages = useMemo(() => {
    notificationsManager.setMappingDataForNotifications(conversations).setNavigate(navigate)
    return sortByDate(
      conversationsWithFormattedName.filter(({ unreadMessagesCount }) => unreadMessagesCount > 0),
      'lastMessage.updatedAt',
      'desc'
    )
  }, [conversationsWithFormattedName, conversations, navigate])

  const chatPrimaryAction: PrimaryAction = useMemo(
    () => ({
      name: t('chatsMenuAlt'),
      icon: <VerticalMenuIcon />,
      popperOptions: [
        {
          name: t('openDirectMessages'),
          icon: <PlusIcon />,
          onClick: onDirectMessageClick,
        },
        {
          name: t('seeTeammates'),
          icon: <TeammatesIcon />,
          onClick: onTeammatesClick,
        },
      ],
    }),
    []
  )

  const chatSecondaryAction: PopperAction = useMemo(
    () => ({
      name: t('newMessage'),
      icon: <PlusIcon />,
      onClick: onCreateChatClick,
    }),
    [onCreateChatClick, t]
  )

  const channelSecondaryAction: PopperAction = useMemo(
    () => ({
      name: t('createChannel'),
      icon: <PlusIcon />,
      onClick: onCreateChannelClick,
    }),
    [onCreateChannelClick, t]
  )

  const channelPrimaryAction: PrimaryAction = useMemo(
    () => ({
      name: t('channelsMenuAlt'),
      icon: <VerticalMenuIcon />,
      popperOptions: [
        {
          name: t('browseChannels'),
          icon: <ListIcon />,
          onClick: () => navigate(routes.channels),
        },
      ],
    }),
    [navigate, t]
  )

  return (
    <>
      {isLoading ? (
        <>
          <SidebarContentSkeleton />
        </>
      ) : (
        <>
          <ScrollContainer hideScrollbar>
            <CollapsibleList
              itemList={sortedUnreadMessages}
              title={t('unread')}
              icon={<UnreadIcon isUnread={sortedUnreadMessages.length > 0} />}
              onItemClick={onChatClick}
              isOpenByDefault={false}
            />
            <CollapsibleList
              itemList={sortedChannels}
              title={t('channels')}
              icon={<HashTagIcon />}
              onItemClick={onChannelClick}
              isOpenByDefault={true}
              primaryAction={channelPrimaryAction}
              secondaryAction={channelSecondaryAction}
            />
            <CollapsibleList
              itemList={sortedChats}
              title={t('directMessages')}
              icon={<DirectMessageIcon />}
              onItemClick={onChatClick}
              isOpenByDefault={true}
              primaryAction={chatPrimaryAction}
              secondaryAction={chatSecondaryAction}
            />
          </ScrollContainer>
        </>
      )}
    </>
  )
}
