import { ImageListType } from 'react-images-uploading'
import styled from 'styled-components'
import { Container } from 'ui'

export const DragContainer = styled(Container)<{ images: ImageListType }>`
  width: 100%;
  min-height: 40rem;
  border-width: 2px;
  border-color: ${(props) => props.theme.colors.grey};
  border-radius: 1.6rem;
  border-style: ${(props) => (props.images.length ? 'none' : 'dashed')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const AvatarContainer = styled(Container)<{ isVertical: boolean }>`
  width: 100%;
  border-radius: 1.6rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40rem;
  background-color: ${({ theme }) => theme.colors.grey};

  img {
    ${({ isVertical }) =>
      isVertical
        ? `
      height: 40rem;
      width: auto;
      object-fit: contain;
    `
        : `
      height: auto;
      width: 100%;
      object-fit: initial;
    `}
  }
`
