import { TFunction } from 'next-i18next'
import * as Yup from 'yup'
import { InviteWsMembersValues } from './types'

export const initialValues: InviteWsMembersValues = {
  memberRole: 'member',
  users: [],
}

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    memberRole: Yup.string().required(),
    users: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          value: Yup.string().email(t('errors.invalidEmail')),
          label: Yup.string(),
          isDuplicated: Yup.bool().oneOf([false], t('errors.isDuplicated')),
        })
      ),
  })
