import { useSpacer } from '@hooks/use-spacer'
import { MessageAttachments } from '@modules/message-attachments'
import { isExpectedType } from '@shared/lib/media/is-expected-type'
import { MetadataType } from '@shared/types/meta-data'
import React, { RefObject, useRef, FC } from 'react'
import { SpinnerLightAnimation, Text } from 'ui'
import { GeneralMessageProps, MessageVariant } from '.'
import { MessageBody, MessageFooter, MessageText, Spacer } from './styled'

export const SendingMessage: FC<GeneralMessageProps> = ({
  attachments,
  uploadingAttachments,
  text,
}) => {
  const messageFooterRef: RefObject<HTMLDivElement> = useRef(null)
  const { spacerWidth } = useSpacer({
    refComponent: messageFooterRef,
    offset: 10,
    hostContent: text,
  })

  const hasUploadingAttachments = uploadingAttachments?.length > 0

  const hasUploadingOnlyImagesOrVideo =
    hasUploadingAttachments &&
    uploadingAttachments.every((attachment) => {
      return isExpectedType(attachment.metadata!, [MetadataType.IMAGE, MetadataType.VIDEO])
    })

  const hasText = text.length > 0

  return (
    <MessageBody
      isSender={false}
      variant={MessageVariant.IS_SENDING}
      hasOnlyImagesOrVideo={hasUploadingOnlyImagesOrVideo}
    >
      {(attachments?.length > 0 || hasUploadingAttachments) && (
        <MessageAttachments
          uploadingAttachments={uploadingAttachments}
          attachments={attachments}
          hasTimestamp={!hasText}
          hasOnlyImagesOrVideo={hasUploadingOnlyImagesOrVideo}
          isIncoming={false}
          isLoading={true}
          pb={hasText ? '0.8rem' : '0'}
        />
      )}
      {hasText && (
        <>
          <MessageText>
            <Text>
              {text}
              <Spacer width={spacerWidth} />
            </Text>
          </MessageText>
          <MessageFooter ref={messageFooterRef}>
            <SpinnerLightAnimation size={16} />
          </MessageFooter>
        </>
      )}
    </MessageBody>
  )
}
