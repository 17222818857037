import Skeleton from 'react-loading-skeleton'
import styled, { keyframes } from 'styled-components'

const fadeColor = (color: string) => keyframes`
  0% {
    background-color: ${color};
    opacity: 0.1;
  }
  50% {
    background-color: ${color};
    opacity: 1;
  }
  100% {
    background-color: ${color};
    opacity: 0.1;
  }
`

export const FadingSkeleton = styled(Skeleton)<{ fadeColor: string }>`
  animation: ${(props) => fadeColor(props.fadeColor)} 3s ease-out forwards infinite;
`
