import { workspaceApiInstance } from '@api/instance'

interface GetWorkspaceAvatar {
  workspaceId: string
  width?: number
  height?: number
}

export const getWorkspaceAvatar = async ({ workspaceId, width, height }: GetWorkspaceAvatar) => {
  const params = {
    ...(width && { width }),
    ...(height && { height }),
  }
  const { data } = await workspaceApiInstance.get(`workspaces/${workspaceId}/avatar`, { params })
  return data
}
