import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, ConfirmationModal } from 'ui'

// import {  } from '../confirmation'

interface DeleteMessageModalProps {
  onSubmit: VoidFunction
}

export const DeleteMessageModal: FC<DeleteMessageModalProps> = ({ onSubmit }) => {
  const { t } = useTranslation('chat')

  return (
    <ConfirmationModal
      title={t('modalDeleteMessage.title')}
      cancelLabel={t('modalDeleteMessage.cancel')}
      submitLabel={t('modalDeleteMessage.submit')}
      onSubmit={onSubmit}
      variant="warning"
    >
      <Text mb="4rem">{t('modalDeleteMessage.confirmation')}</Text>
    </ConfirmationModal>
  )
}
