import { useAppSelector } from '@app/flow/hooks'
import { Attachments as AttachmentsNfc } from '@modules/native-chats/attachments'
import { useOpenGalleryModal } from '@shared/lib/hooks/use-open-gallery-modal'
import React, { FC, useCallback } from 'react'
import { AttachmentResponse } from 'src/entities/messages/api/types/attachment-response'
import { SpaceProps, WidthProps } from 'styled-system'
import { selectChatUsers } from '../../features/chats/slice'
import { Attachments } from './attachments'

export interface AttachmentsContainerProps {
  hasTimestamp: boolean
  isIncoming?: boolean
}

export interface MessageAttachmentsProps extends AttachmentsContainerProps, SpaceProps, WidthProps {
  conversationId: string
  attachments: AttachmentResponse[]
  uploadingAttachments: AttachmentResponse[]
  hasOnlyImagesOrVideo: boolean
  createdByUserId: string //createdBy and createdAt BE doesn't provide in the attachment object, we pass it from the message
  createdAt?: Date
  isLoading?: boolean
}

export const MessageAttachments: FC<MessageAttachmentsProps> = ({
  conversationId,
  attachments,
  uploadingAttachments,
  hasTimestamp,
  hasOnlyImagesOrVideo = false,
  isIncoming = false,
  isLoading = false,
  createdAt,
  createdByUserId,
  width,
  ...spacing
}) => {
  const chatUsers = useAppSelector(({ chats }) => selectChatUsers(chats, conversationId || ''))

  const getSender = useCallback(
    (userId: string) => {
      return chatUsers?.find((u) => u.user.userId === userId)?.user
    },
    [chatUsers]
  )

  const handleOnClick = useOpenGalleryModal({
    conversationId,
    userId: createdByUserId,
    attachments: attachments || uploadingAttachments,
    getSender,
    createdAt,
  })

  return (
    <Attachments
      uploadingAttachments={uploadingAttachments}
      attachments={attachments}
      conversationId={conversationId}
      createdAt={createdAt}
      hasTimestamp={hasTimestamp}
      onClick={handleOnClick}
      isIncoming={isIncoming}
      isLoading={isLoading}
      hasOnlyImagesOrVideo={hasOnlyImagesOrVideo}
      width={width}
      {...spacing}
    />
  )
}
