import { TFunction } from 'i18next'
import { ConversationType } from '../../model/conversation-type'

interface ConversationUser {
  userId: string
  firstName: string
  lastName: string
  isDeleted: boolean
}

export interface GenericConversationUser<U> {
  user: U
}
export interface GenericConversation<T, U> {
  conversationType: T
  chatUsers: GenericConversationUser<U>[]
  name: string
}

export const formatConversationName = <
  T extends string = ConversationType,
  U extends {
    userId: string
    isDeleted: boolean
    firstName: string
    lastName: string
  } = ConversationUser
>(
  conversation: GenericConversation<T, U>,
  myUserId: string,
  t: TFunction
): string => {
  let result = ''
  const users = conversation.chatUsers || []
  const usersSendingToMe: GenericConversationUser<U>[] = users?.filter(
    ({ user: { userId } }) => userId !== myUserId
  )

  if (conversation.conversationType === ConversationType.GENERAL_CHANNEL) {
    return t('generalChannelName')
  }

  if (conversation.conversationType !== ConversationType.CHAT) {
    return conversation.name
  }

  if (users.length === 1 && users[0].user.userId === myUserId) {
    //  Personal chat
    const { firstName, lastName } = users[0].user
    result = `${firstName} ${lastName} (${t('chatName.you')})`
  } else if (usersSendingToMe.length === 1) {
    // 1 to 1 chat
    const { isDeleted, firstName, lastName } = usersSendingToMe[0].user
    result = isDeleted ? t('chatName.deactivatedAccount') : `${firstName} ${lastName}`
  } else if (usersSendingToMe.length > 1) {
    //  2 and more chat users excl. my user
    const firstTwoUsers = usersSendingToMe
      .slice(0, 2)
      .map(({ user: { isDeleted, firstName } }) =>
        isDeleted ? t('chatName.deactivatedAccount') : firstName
      )
      .join(', ')
    const remainingCount = usersSendingToMe.length - 2

    result =
      remainingCount > 0
        ? t(`chatName.otherUsers`, {
            userNames: firstTwoUsers,
            count: remainingCount,
          })
        : firstTwoUsers
  }

  return result
}
