import { UploadAvatarArgs } from '../types'
import { uploadBlob } from './crop-utils'

export const uploadAvatar = async ({ blob, uri }: UploadAvatarArgs) => {
  if (!blob) {
    throw new Error('Blob is required for avatar upload')
  }

  const file = new File([blob], 'avatar.png', { type: blob.type })
  await uploadBlob(uri, file, file.type)
}
