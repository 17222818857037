import { useGetMe } from '@api/account/hooks/use-get-me'
import { User } from '@api/account/types/user'
import { useCreateChat } from '@api/chats/hooks/use-create-chat'
import { useGetChatList } from '@api/chats/hooks/use-get-chat-list'
import { ChatUser } from '@api/chats/types/chat-user'
import { useGetMessageList } from '@api/messages/hooks/use-get-message-list'
import { ChipInput } from '@components/chip-input'
import { ChipInputItemProps } from '@components/chip-input/chip-input-item'
import { MessageInput, MessageInputMode } from '@components/message-input'
import { AttachmentsUploadingProvider } from '@contexts/attachments-uploading-provider'
import { useAttachments } from '@hooks/use-attachments'
import { useDebounce } from '@hooks/use-debounce'
import { ConnectionScope } from '@native-chats-api/accounts/generated'
import { routes } from '@routes/chats/routes'
import { formatMessages } from '@utils/format-messages'
import React, { FC, Suspense, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useGetContacts } from 'src/features/chats-contacts/hooks/get-contacts'
import { Container, Heading } from 'ui'
import { ConversationBody } from '../../entities/conversation/conversation-body'
import { ConversationSkeleton } from '../../entities/conversation/conversation-body/ui/modules/conversation-skeleton/conversation-skeleton'
import { ConversationType } from '@shared/types/conversation'

export const NativeChatsCreateNew: FC = () => {
  const [chatUserList, setChatUserList] = useState<User[]>([])
  const [inputText, setInputText] = useState<string>('')
  const [existingChatId, setExistingChatId] = useState<string | undefined>(undefined)
  const [contentEditableInputText, setContentEditableInputText] = useState<string>('')
  const { t } = useTranslation(['create-new'])
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const preSendUser = searchParams.get('userId')
  const [preSendChipItem, setPreSendChipItem] = useState<ChipInputItemProps | null>(null)
  const { meData } = useGetMe()
  const { chats } = useGetChatList()
  const chatUsersIdsList = chatUserList.map(({ userId }) => userId)
  const isUserSelectedSomeone =
    meData && chatUserList.length && !chatUsersIdsList.includes(meData.userId)

  const { data: users = [] } = useGetContacts(ConnectionScope._0)

  const [attachments, onAttachmentsChange] = useAttachments()

  //TODO chat creation shoudn't be debounce on release. it is temporary solution. Problem is that we should to send post request each time
  // wee add new user to new chat composer, because we geting info about existing chat with post request also (if we want to get it by users, not chat id)
  // and we don't know chat id during it. Debounce required for dev, to not spam with chat creating when you want to add several users.
  // Possible soultions I see in future could be find in signalR and storing data locally
  const chatUserListAfterDebounce = useDebounce(chatUserList, 1000)

  const isMyUser = (userId: string) => userId === meData?.userId

  const redirectUser = (conversationId: string) => {
    const inputTextValue = inputText
    setInputText('')
    setContentEditableInputText('')
    navigate(`/${routes.chats}/${conversationId}`, {
      replace: true,
      // Passing state flag 'refetchMessages' to re-fetch messages after redirect
      // Note: messages are fetched once per chatId change
      // In case of creating of a new channel, id changed before we send a message and redirect user
      state: {
        key: 'refetchMessages',
        id: conversationId,
        text: inputTextValue,
      },
    })
  }

  const {
    data: createChatData,
    createChat,
    isCreateChatLoading,
  } = useCreateChat({
    onErrorCallback: () => toast.error(`${t('somethingWentWrong')}`),
  })

  const { messages } = useGetMessageList({
    chatId: createChatData?.id || existingChatId,
    pageNumber: 1,
    pageSize: 10,
  })

  //TODO: described here NAT-2880
  const tryToFindExistingChat = () => {
    // Check if user has selected himself in the input
    if (isUserSelectedSomeone) {
      chatUsersIdsList.push(meData.userId)
    }

    const chatId = chats.find(
      ({ chatUsers }) =>
        chatUsers.length === chatUsersIdsList.length &&
        chatUsers.every(({ user: { userId } }) => chatUsersIdsList.includes(userId))
    )?.id

    if (existingChatId !== chatId) {
      setExistingChatId(chatId)
    }
  }

  const userMap = useMemo(() => {
    let chatUsers = users.map((user) => {
      let chatUser: ChatUser = {
        user: user,
        idOfLastMessageRead: '',
        lastMessageReadAt: new Date(),
        isOwner: false,
        leftChat: false,
      }

      return chatUser
    })

    tryToFindExistingChat()
    return chatUsers.reduce((acc: Record<string, ChatUser>, chatUser) => {
      acc[chatUser.user.userId] = chatUser

      return acc
    }, {})
  }, [users])

  const chipInputOptions = useMemo(
    () =>
      users.map(
        (user) =>
          ({
            ...user,
            value: user.userId,
            label: `${user.firstName} ${user.lastName} ${
              isMyUser(user.userId) ? `(${t('you')})` : ''
            }`,
            avatarId: user.avatar.mediaId,
            color: user.color,
            online: user.online,
            avatarUri: user.avatar.mediaUri,
          } as ChipInputItemProps)
      ),
    [users]
  )

  const chipInputOptionsWithoutMe = chipInputOptions?.filter(({ value }) => !isMyUser(value))

  useEffect(() => {
    tryToFindExistingChat()
  }, [chatUserList])

  useEffect(() => {
    if (preSendUser) {
      const user = users?.find(({ userId }) => userId === preSendUser)
      if (!user) return
      setPreSendChipItem({
        ...user,
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
        avatarId: user.avatar?.mediaId,
        color: user.color,
        online: user.online,
        avatarUri: user.avatar.mediaUri,
      } as ChipInputItemProps)
      setChatUserList([user])
    }
  }, [preSendUser])

  useEffect(() => {
    if (createChatData) {
      redirectUser(createChatData.id)
    }
  }, [createChatData])

  const handleSendText = () => {
    if (existingChatId) {
      redirectUser(existingChatId)
      return
    }

    const filteredUserIds = chatUserListAfterDebounce
      .map(({ userId }) => userId)
      .filter((userId) => !isMyUser(userId))

    if (filteredUserIds.length === 0) return
    createChat({ userIds: filteredUserIds })
  }

  const formattedMessages = useMemo(
    () => formatMessages({ messages, users: userMap, meData }),
    [messages, userMap, meData]
  )

  return (
    <Suspense fallback={<ConversationSkeleton />}>
      <AttachmentsUploadingProvider value={attachments} onChange={onAttachmentsChange} multiple>
        <Container
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
          flex="1 1 auto"
        >
          <Container mb="2.4rem">
            <Heading mb="1.2rem">{t('createNewMessage')}</Heading>
            <ChipInput
              options={isUserSelectedSomeone ? chipInputOptionsWithoutMe : chipInputOptions}
              isMulti
              setOptionsList={setChatUserList}
              defaultValue={preSendChipItem}
            />
          </Container>
          {chatUserList.length > 0 && (
            <ConversationBody
              setIsInView={() => {}}
              messages={formattedMessages}
              type={ConversationType.CHAT}
              users={chatUserList}
            />
          )}
          <Container>
            {/* TODO: temporary solution to hide dummy typing when there are no messages */}
            <Container pt="3.2rem" position="relative">
              {
                // TODO: implement typing indicator, new message loading and loading of previous messages if needed
                // So as typing indicator wasn't implemented and working properly it was temporary removed from here
              }
              <MessageInput
                inView={false}
                scrollDownHandle={() => {}}
                inputText={inputText}
                contentEditableInputText={contentEditableInputText}
                setContentEditableInputText={setContentEditableInputText}
                setInputText={setInputText}
                onClickSend={handleSendText}
                isDisabled={chatUserList.length === 0 || isCreateChatLoading}
                messageInputMode={MessageInputMode.SEND}
              />
            </Container>
          </Container>
        </Container>
      </AttachmentsUploadingProvider>
    </Suspense>
  )
}
