import { FadingSkeleton } from '@modules/skeleton/skeleton-variants/fading-skeleton'
import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Container } from 'ui'

interface MessageSkeletonProps {
  messageWidth: number
  isIncoming?: boolean
}

export const MessageSkeleton: FC<MessageSkeletonProps> = ({ messageWidth, isIncoming = false }) => {
  const theme = useTheme()

  const messageSkeletonBorderRadius = isIncoming
    ? { borderRadius: 16, borderTopLeftRadius: 0 }
    : { borderRadius: 16, borderBottomRightRadius: 0 }

  return (
    <Container display="flex" alignSelf={isIncoming ? 'flex-start' : 'flex-end'}>
      {isIncoming && (
        <FadingSkeleton
          width={36}
          height={36}
          baseColor={theme.colors.beigeDark}
          borderRadius={6}
          style={{ marginRight: '0.8rem' }}
          fadeColor={theme.colors.beigeDark}
        />
      )}
      <FadingSkeleton
        height={48}
        width={messageWidth}
        baseColor={theme.colors.beigeDark}
        style={messageSkeletonBorderRadius}
        fadeColor={theme.colors.beigeDark}
      />
    </Container>
  )
}
