import { getWorkspaceAvatar } from '@api/workspaces/get-workspace-avatar'
import { AVATAR_STALE_TIME } from '@hooks/constants'
import { useQuery } from '@tanstack/react-query'
import { AVATAR_PREVIEW_WIDTH } from '@utils/variables'

export const WORKSPACE_AVATAR = 'workspace_avatar'

interface UseGetWorkspaceAvatar {
  workspaceId: string
  avatarId?: string
  width?: number
  height?: number
}

export const useGetWorkspaceAvatar = ({
  workspaceId,
  avatarId,
  width = AVATAR_PREVIEW_WIDTH.MD,
  height,
}: UseGetWorkspaceAvatar) => {
  const enabled = Boolean(workspaceId && avatarId)

  const { data, isError, isLoading, error } = useQuery(
    [WORKSPACE_AVATAR, workspaceId, avatarId, width, height],
    () => getWorkspaceAvatar({ workspaceId: workspaceId!, width, height }),
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: AVATAR_STALE_TIME,
    }
  )
  return { workspaceAvatarUrl: data, isError, isLoading, error, enabled }
}
