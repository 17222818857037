import { useGetMe } from '@api/account/hooks/use-get-me'
import { User } from '@api/account/types/user'
import { getFullName } from '@utils/helpers'
import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SpaceProps } from 'styled-system'
import { Container, Text } from 'ui'
import { UserInfo, UserInfoText } from './user-info'

export enum ConversationType {
  MY_SPACE = 'myspace',
  DM = 'dm',
  GROUP = 'group',
}

interface EmptyChatMessagesProps extends SpaceProps {
  users: User[]
}

interface ConversationState {
  type: ConversationType
  otherUsers: User[]
  firstUser: User
  message: string
  userInfoText?: UserInfoText
}

export const EmptyChatMessages: FC<EmptyChatMessagesProps> = ({ users, ...spacing }) => {
  const { t } = useTranslation(['create-new'])
  const { meData } = useGetMe()
  const { chatId } = useParams()

  const otherUsers = users.filter(({ userId }) => userId !== meData?.userId)
  const isSelfChat = users.length === 1 && users[0].userId === meData?.userId
  const isDM = chatId ? users.length === 2 : users.length === 1 // Already existing DMs have 2 users, DM in state of creation has 1 user
  const firstUser = isSelfChat ? users[0] : otherUsers[0]

  const conversationState = useMemo<ConversationState>(() => {
    let message: string
    let type: ConversationType
    let userInfoText: { title: string; subtitle?: string }

    if (isSelfChat) {
      type = ConversationType.MY_SPACE
      message = `${t('privateChatPart1')} ${t('privateChatPart2')}`
      userInfoText = {
        title: `${firstUser.firstName} (${t('you')})`,
        subtitle: firstUser.jobTitle,
      }
    } else if (isDM) {
      type = ConversationType.DM
      const userName = getFullName(firstUser.firstName, firstUser.lastName)
      message = `${t('infoOneUser', { userNames: userName })} ${t('accessRestricted')}`
      userInfoText = {
        title: userName,
        subtitle: firstUser.jobTitle,
      }
    } else {
      type = ConversationType.GROUP
      if (otherUsers.length === 2) {
        message = `${t('infoTwoUsers', {
          userNameOne: otherUsers[0].firstName,
          userNameTwo: otherUsers[1].firstName,
        })} ${t('accessRestricted')}`
      } else {
        const firstTwoNames = otherUsers
          .slice(0, 2)
          .map((user) => user.firstName)
          .join(', ')
        message = `${t('infoMultipleUsers', {
          userNames: firstTwoNames,
          count: otherUsers.length - 2,
        })} ${t('accessRestricted')}`
      }
      userInfoText = { title: '' }
    }

    return {
      type,
      otherUsers,
      firstUser,
      message,
      userInfoText,
    }
  }, [users, chatId, t, otherUsers, isSelfChat, isDM])

  return (
    <Container {...spacing}>
      <UserInfo
        type={conversationState.type}
        firstUser={conversationState.firstUser}
        otherUsers={conversationState.otherUsers}
        userInfoText={conversationState.userInfoText}
      />
      <Text mt="1.6rem">{conversationState.message}</Text>
    </Container>
  )
}
