import { AttachmentItem } from '@modules/attachment-item'
import { AttachmentProps } from '@modules/attachment-item/attachment-props'
import { useVideoThumbnail } from '@shared/lib/hooks/use-video-thumbnail'
import { bytesToSize } from '@shared/lib/media/bytes-to-size'
import { MIN_WIDTH_ATTACHMENT_TILE } from '@utils/variables'
import React from 'react'
import { ImageType } from 'react-images-uploading'

interface InputAttachmentsProps extends AttachmentProps {
  attachment: ImageType
  index: number
  onAttachmentRemove: (index: number) => void
  handleOnClick: (index: number) => void
}

export const InputAttachment = ({
  onAttachmentRemove,
  index,
  attachment,
  handleOnClick,
}: InputAttachmentsProps) => {
  const { thumbnail, loading } = useVideoThumbnail(attachment.file)

  return (
    <>
      <AttachmentItem
        isLoading={loading}
        thumbnail={thumbnail}
        key={index}
        onClick={() => handleOnClick(index)}
        name={attachment.file?.name}
        src={attachment?.dataURL}
        size={attachment.file?.size && bytesToSize(attachment.file?.size)}
        onRemove={() => onAttachmentRemove(index)}
        contentType={attachment.file?.type}
        width={`${MIN_WIDTH_ATTACHMENT_TILE}rem`}
      />
    </>
  )
}
